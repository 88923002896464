/* eslint-disable no-console */
import axios from "axios";
import moment from "moment";
import nextCookie from "next-cookies";
import { AppProps } from "next/app";
import { NextComponentType, NextPageContext } from "next/dist/shared/lib/utils";
import Head from "next/head";
import Router from "next/router";
import NProgress from "nprogress";
import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { Store } from "redux";

import { listTravellers } from "shared/cbt/api/travellers";
import { getEnvironmentFromRequestorClientId } from "shared/config/utils";
import { usersFetched } from "shared/data/actions/cbt";
import { setRequestorConfig } from "shared/data/actions/requestorConfig";
import {
  getGOLAPIUrl,
  loadAirlines,
  loadCountries,
  loadWebRequestorDetails,
  setAirlines,
  setCountries,
} from "shared/data/actions/storage";
import { logoutUser, setUserValues } from "shared/data/actions/user";
import {
  getCbtToken,
  logoutCustomer,
  setCustomerDevice,
  setCustomerToken,
} from "shared/data/customerTokens";
import detailCustomer2 from "shared/gol-api/detailCustomer2";
import { getRequestorClientId } from "shared/lib/functions";
// @ts-ignore - Ignore the module error
import { POSSIBLE_LANGUAGES } from "shared/lib/languages";
import { getInitialClientId, setLocale } from "shared/lib/requestorFunctions";
// @ts-ignore
import Logger from "shared/services/Logger";
// @ts-ignore
import { getDomainForFileServer } from "shared/services/requestorConfiguration";

import LoginForm from "@components/Login/Form";
import ErrorLayout from "@components/UI/Layouts/ErrorLayout";
import StandardLayout from "@components/UI/Layouts/StandardLayout";

import { pageDataLayer } from "@lib/dataLayers";
import withReduxStore from "@lib/with-redux-store";
import * as Sentry from "@sentry/browser";
import { ROUTES } from "@shared/constants";

import { timeBenchmark } from "../lib/benchmarks";
import "../styles/main.scss";

const config = require("shared/config.json");
const { generateRandomString } = require("../lib/serverHelpers.ts");

Router.events.on("routeChangeStart", () => NProgress.start());
Router.events.on("routeChangeComplete", () => NProgress.done());
Router.events.on("routeChangeError", () => NProgress.done());

const DEFAULT_LANG_GLOBAL = "cs";

const errorRoutes = ["/404", "/_error"];

Sentry.init({
  dsn: config.sentryUrl,
  environment: getEnvironmentFromRequestorClientId().label,
  ignoreErrors: ["_abt/*init*/(arg[0]='isArray')"],
});

interface IPageProps {
  domain?: string;
  fileServerPagesToDownload?: string[];
  gtm?: {
    gtmId?: any;
    nonce?: string;
  };
  downloadedPages?: Record<string, any>;
}

type ExtendedComponent = NextComponentType<NextPageContext, any, any> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

interface IAppProps {
  reduxStore: Store;
  pageProps: IPageProps;
  randomString: string;
}

const cache = {};

async function fetchWithCache(cacheKey, lang, ttl, fetchFunction, domain) {
  if (
    cache[cacheKey] &&
    cache[cacheKey][lang] &&
    Date.now() - cache[cacheKey][lang].timestamp < ttl
  ) {
    console.log(
      `Cache - (hit) ${cacheKey} for ${lang} expire at: ${new Date(
        cache[cacheKey][lang].timestamp + ttl
      ).toLocaleString()}`
    );

    return cache[cacheKey][lang].data;
  }

  console.log(`Cache - (miss) Fetching new data for ${cacheKey} for ${lang}`);
  const data = await timeBenchmark(
    async () => fetchFunction(),
    null,
    `Fetching data for ${cacheKey} for ${lang} (${domain})`
  );

  if (!cache[cacheKey]) {
    cache[cacheKey] = {};
  }

  cache[cacheKey][lang] = {
    data,
    timestamp: Date.now(),
  };

  console.log(
    `Cache - stored ${cacheKey} for ${lang} expire at: ${new Date(
      cache[cacheKey][lang].timestamp + ttl
    ).toLocaleString()}`
  );

  return data;
}

const transformAlbanianLanguageShortcut = (lang: string) => {
  return lang === "al" ? "sq" : lang;
};

const prepareCbtUsers = async ({ reduxStore }) => {
  const cbtToken = await getCbtToken();

  const { frontendSettings } = reduxStore.getState().storage;

  const allUsers = await listTravellers({
    cbtToken,
    customerUsername: reduxStore.getState().user.email,
    cbtApiUrl: frontendSettings.dealerCorporateSettings?.cbtApiUrl,
    limit: 1000,
  });

  if (allUsers?.success) {
    const enhancedUsers = allUsers.data.data.map((user) => ({
      ...user,
      value: user?.Id,
      label: `${user?.FirstName} ${user?.LastName}`,
    }));

    await reduxStore.dispatch(
      usersFetched({
        success: allUsers.success,
        data: { data: enhancedUsers },
      })
    );
  }
};

const handleErrors = (error, errorInfo) => {
  Sentry.withScope((scope) => {
    Object.keys(errorInfo).forEach((key) => {
      scope.setExtra(key, errorInfo[key]);
    });
    // @ts-ignore
    Sentry.captureException(error);
  });
};

function MyApp({
  Component,
  pageProps,
  router,
  reduxStore,
  randomString,
}: AppProps & IAppProps) {
  const TypedComponent = Component as ExtendedComponent;
  useEffect(() => {
    const errorHandler = (error) => {
      handleErrors(error, { componentStack: "" });
    };
    window.addEventListener("error", errorHandler);
    return () => window.removeEventListener("error", errorHandler);
  }, []);

  useEffect(() => {
    const lang = reduxStore.getState().requestorConfig.currentLanguage;

    const transformedLang = transformAlbanianLanguageShortcut(lang);
    moment.locale(transformedLang);
    setLocale(transformedLang);

    const { user, storage } = reduxStore.getState();

    const isCbtUser =
      storage?.frontendSettings.dealerCorporateSettings?.enableCbt === "true";

    if (user?.isLoggedIn && isCbtUser) {
      prepareCbtUsers({ reduxStore });
    }

    // @ts-ignore - Cypress adds store to window for testing
    if (window.Cypress) {
      // @ts-ignore
      window.store = reduxStore;
    }

    const requestorClientId =
      process.env.NEXT_PUBLIC_D4_requestorClientId || config.requestorClientId;
    // @ts-ignore - spyTester is a global analytics tool
    if (requestorClientId.includes(".gol.") && window.spyTester) {
      // @ts-ignore
      window.spyTester.init({ idSite: "tpd4" });
    }
    if (router.route?.includes(`${ROUTES.RESERVATION}/`)) {
      pageDataLayer("other", user);
    } else {
      pageDataLayer("home", user);
    }
  }, [reduxStore, router]);

  const isAnonymousSearchEnabled =
    reduxStore.getState().storage.frontendSettings.dealerCorporateSettings
      ?.enableAnonymousSearch === "true";

  const isLoginIframe =
    (router.route.includes(ROUTES.IFRAME) ||
      router.route.includes(ROUTES.LOW_COST_SEARCH)) &&
    isAnonymousSearchEnabled === false;

  return (
    <Provider store={reduxStore}>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <div className="layout-wrapper">
        <div
          style={{ position: "absolute" }}
          dangerouslySetInnerHTML={{
            __html: `<!--${randomString}-->`,
          }}
        />
        {(() => {
          if (TypedComponent.getLayout) {
            return TypedComponent.getLayout(
              <TypedComponent {...pageProps} router={router} />
            );
          }
          if (errorRoutes.includes(router.route)) {
            return (
              <ErrorLayout>
                <TypedComponent {...pageProps} router={router} />
              </ErrorLayout>
            );
          }

          return (
            <StandardLayout
              route={router.route}
              nonce={pageProps?.gtm?.nonce}
              hideSearchForm={isLoginIframe}
            >
              {isLoginIframe && <LoginForm />}
              <TypedComponent
                {...pageProps}
                showloginIframeTitle={isLoginIframe}
                router={router}
              />
            </StandardLayout>
          );
        })()}
      </div>
    </Provider>
  );
}

async function downloadPages(urls, fileServerData, domain) {
  try {
    const result = {};

    if (!fileServerData) {
      return result;
    }

    const downloadPromises = urls
      .filter((url) => !!url)
      .map((url) => {
        let fileUrl = fileServerData[`data/${url}.html`];

        if (
          typeof window === "undefined" &&
          config.fileServerApiUrlInternal &&
          fileUrl &&
          fileUrl.includes("localhost:")
        ) {
          const urlObject = new URL(fileUrl);
          fileUrl = fileUrl.replace(
            `${urlObject.origin}/files/`,
            config.fileServerApiUrlInternal
          );
        }

        if (!fileUrl) {
          return null;
        }

        return timeBenchmark(
          async () => axios.get(fileUrl),
          null,
          `Downloading page: ${fileUrl} ${url} (${domain})`
        ).then((page) => ({
          url,
          data: page.data,
        }));
      });

    const fetchedFileServerPages = await timeBenchmark(
      async () => {
        const results = await Promise.all(downloadPromises);
        return results;
      },
      null,
      `Fetching File Server Pages (${domain})`
    );

    fetchedFileServerPages.forEach(({ url, data }) => {
      result[url] = data;
    });

    return result;
  } catch (e) {
    Logger.log(`Error dowloading pages (${domain}): `, JSON.stringify(e));
  }
}

async function fetchFileServerData(urlToAsk) {
  try {
    const data = await axios.get(urlToAsk);
    return data.data;
  } catch (e) {
    Logger.error(`Error fetching file server data from ${urlToAsk}:`, e);
    return null;
  }
}

async function loadStorageData({ domain, lang, reduxStore, req }) {
  if (!domain) {
    return;
  }

  if (reduxStore.getState().requestorConfig.fileServerData !== null) {
    return reduxStore.getState().requestorConfig.fileServerData;
  }

  try {
    const domainForFileServer = getDomainForFileServer(domain);

    const isLocalhost = req?.headers.host?.includes("localhost:");

    const baseUrl =
      isLocalhost && config.fileServerApiUrlInternal
        ? config.fileServerApiUrlInternal
        : config.fileServerApiUrl;

    const fileServerUrlEnv =
      process.env.NEXT_PUBLIC_D4_fileServerApiUrl ||
      (typeof window !== "undefined" &&
        window?.__ENV?.NEXT_PUBLIC_D4_fileServerApiUrl);

    const urlToAsk = `${fileServerUrlEnv || baseUrl}${domainForFileServer}/${
      lang === "cz" ? "cs" : lang
    }/index.json`;

    if (lang) {
      const fileServerData = await timeBenchmark(
        async () => fetchFileServerData(urlToAsk),
        null,
        `Fetching File Server Data (${domain})`
      );

      if (domain.includes("localhost") || domain.includes("d4:")) {
        // eslint-disable-next-line security/detect-non-literal-require -- this is only for localhost && no user input
        const defaultTextstorage = require(`./../../shared/lang/${
          lang === "cz" ? "cs" : lang
        }.json`);
        await reduxStore.dispatch(
          setRequestorConfig("textStorage", defaultTextstorage)
        );
      } else if (fileServerData && fileServerData["textstorage.json"]) {
        let urlTextStorage = fileServerData["textstorage.json"];
        if (
          urlTextStorage.includes("https:") &&
          (urlTextStorage.includes("localhost") ||
            urlTextStorage.includes("ao3-resources"))
        ) {
          urlTextStorage = `http://${
            fileServerData["textstorage.json"].split("ttps://")[1]
          }`;
        }

        if (!urlTextStorage || urlTextStorage === "") {
          console.log(
            `No textstorage.json file found for agency ${domain}, probably wrongly configured.`
          );
        } else {
          const textStorageFetched = await timeBenchmark(
            async () => axios.get(urlTextStorage),
            null,
            "Fetching Text Storage"
          );

          const textStorage = textStorageFetched.data;
          await reduxStore.dispatch(
            setRequestorConfig("textStorage", textStorage)
          );
        }
      }

      await reduxStore.dispatch(
        setRequestorConfig("fileServerData", fileServerData)
      );

      return fileServerData;
    }

    console.log(
      `Redirecting to 404 page - No language found for agency ${domain}, probably wrongly configured or requestor does not exists (legacy D3 web).`
    );
    if (typeof window !== "undefined") {
      Router.push(ROUTES.NOT_FOUND);
    } else {
      req.res.redirect(ROUTES.NOT_FOUND);
    }
  } catch (e) {
    Logger.error(
      `Unable to load fileServer storage data for ${domain}. Maybe you have incorrect url?`,
      JSON.stringify(e)
    );
  }
}

function getCustomerToken(ctx) {
  const allCookies = nextCookie(ctx);

  if (!allCookies || !allCookies.d4customerToken) {
    return false;
  }

  return allCookies.d4customerToken;
}

function getCustomerDevice(ctx) {
  const allCookies = nextCookie(ctx);

  if (!allCookies || !allCookies.d4customerDevice) {
    return false;
  }

  return allCookies.d4customerDevice;
}

function getWebRequestorDetails({ menuLanguage, isDefaultLanguage, ctx }) {
  const requestorPublicKey = nextCookie(ctx)?.d4requestorPublicKey;
  const customerToken = nextCookie(ctx)?.d4customerToken;
  const clientId = getInitialClientId(ctx.req.hostname);

  const redirect404 = () => {
    ctx.res.redirect(
      `${ROUTES.NOT_FOUND}?redirectDetail=not_configured_or_disabled_agency`
    );
  };

  return loadWebRequestorDetails({
    selectedLanguage: isDefaultLanguage ? null : menuLanguage,
    golApiUrl: getGOLAPIUrl(ctx.req),
    requestorPublicKey,
    forcedCustomerToken: customerToken,
    forcedClientId: clientId,
    redirect404,
  });
}

MyApp.getInitialProps = async (props) => {
  const {
    Component,
    ctx,
    ctx: { reduxStore },
  } = props;
  const startTime = Date.now();

  let pageProps: IPageProps = {};

  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
    let lang = reduxStore.getState().requestorConfig.currentLanguage; // default
    setLocale(lang);
    let { domain } = pageProps;

    const requestorClientId = getRequestorClientId(ctx);
    const nonce = ctx?.res?.locals?.nonce;

    const { fileServerPagesToDownload = [] } = pageProps;

    /*
    Server side
     */
    if (ctx.req) {
      let menuItemsLang = DEFAULT_LANG_GLOBAL;
      let isDefaultLang = true;
      if (
        ctx.req?.query?.lang !== undefined &&
        POSSIBLE_LANGUAGES.includes(ctx.req.query.lang)
      ) {
        setLocale(ctx.req.query.lang);
        lang = ctx.req.query.lang;
        menuItemsLang = ctx.req.query.lang;
        isDefaultLang = false;
      }

      await timeBenchmark(
        async () =>
          reduxStore.dispatch(
            getWebRequestorDetails({
              menuLanguage: menuItemsLang,
              isDefaultLanguage: isDefaultLang,
              ctx,
            })
          ),
        null,
        `Fetching Requestor Details (${domain})`
      );

      /*
      If isDefaultLang is true language is set based on first returned
      value from DetailRequestor SupportedLanguage attribute
       */
      if (isDefaultLang) {
        lang = reduxStore.getState().requestorConfig.currentLanguage;
        setLocale(lang);
      }

      const restrictAirlines = reduxStore.getState().storage?.frontendSettings
        ?.carrierFilter;

      const cacheTTL = 24 * 60 * 60 * 1000;

      const [countries, airlines] = await Promise.all([
        fetchWithCache(
          "countries_cached",
          lang,
          cacheTTL,
          loadCountries(ctx.req, reduxStore.dispatch),
          domain
        ),
        fetchWithCache(
          "airlines_cached",
          lang,
          cacheTTL,
          loadAirlines(ctx.req, restrictAirlines, reduxStore.dispatch),
          domain
        ),
      ]);

      reduxStore.dispatch(setCountries(countries));
      reduxStore.dispatch(setAirlines(airlines));

      // check logged
      const customerToken = getCustomerToken(ctx);
      const customerDevice = getCustomerDevice(ctx);

      if (customerToken && customerDevice) {
        await setCustomerToken(customerToken);
        await setCustomerDevice(customerDevice);

        const clientId = getInitialClientId(ctx.req.hostname);

        const customerDetail = await timeBenchmark(
          async () =>
            detailCustomer2({
              customerToken,
              requestorPublicKey: nextCookie(ctx)?.d4requestorPublicKey,
              clientId,
              golApiUrl: getGOLAPIUrl(ctx.req),
            }),
          null,
          `Fetching Customer Details (${domain})`
        );

        if (customerDetail.success) {
          await reduxStore.dispatch(
            setUserValues({
              isLoggedIn: true,
              ...customerDetail.data.formattedData,
            })
          );
        } else {
          await logoutCustomer();
          await reduxStore.dispatch(logoutUser());
        }
      } else if (
        reduxStore.getState().storage.frontendSettings.dealerCorporateSettings
          ?.enableAnonymousSearch === "false"
      ) {
        if (!ctx.req?.originalUrl?.includes(ROUTES.IFRAME)) {
          if (typeof window !== "undefined") {
            // Use Router for client-side navigation
            Router.push("/");
          } else if (ctx.req?.originalUrl?.includes(ROUTES.BOOKED)) {
            ctx.res.redirect("/");
          }
        }
      }
    }

    if (!domain && process.browser) {
      domain = window.location.hostname;
    }

    pageProps.gtm = {
      nonce,
    };

    const fileServerData = await loadStorageData({
      domain,
      lang,
      reduxStore,
      req: ctx.req,
    });

    const footerHTML = reduxStore.getState().requestorConfig?.footerInnerHTML;

    if (!footerHTML) {
      fileServerPagesToDownload.push("SF_STANDART_FOOTER");
    }
    const downloadedPages = await downloadPages(
      fileServerPagesToDownload,
      fileServerData,
      domain
    );
    if (downloadedPages) {
      pageProps.downloadedPages = downloadedPages;
      if (downloadedPages?.SF_STANDART_FOOTER) {
        reduxStore.dispatch(
          setRequestorConfig("footerInnerHTML", {
            // @ts-ignore
            html: downloadedPages.SF_STANDART_FOOTER,
            nonce,
            clientId: requestorClientId,
          })
        );
      }
    }
  }

  const randomString = generateRandomString();

  const endTime = Date.now();
  const elapsedTime = endTime - startTime;

  console.log(
    `Time benchmark - Website Initial Load - FINISH. Total load time: ${elapsedTime}ms`
  );

  return { pageProps, randomString };
};

export default withReduxStore(MyApp);

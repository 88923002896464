export const currencies = {
  AFN: "AFN",
  ALL: "L",
  DZD: "DZD",
  AOA: "Kz",
  ARS: "$",
  AMD: "AMD",
  AWG: "ƒ",
  AUD: "$",
  AZN: "₼",
  BSD: "$",
  BHD: "BD",
  BDT: "Tk",
  BBD: "$",
  BZD: "$",
  BMD: "$",
  BOB: "$b",
  BAM: "KM",
  BWP: "BWP",
  BRL: "R$",
  GBP: "£",
  BND: "$",
  BGN: "Лв",
  MMK: "K",
  KHR: "៛",
  CAD: "C$",
  CVE: "Esc",
  KYD: "$",
  XOF: "Fr",
  XAF: "Fr",
  XPF: "Fr",
  CLP: "$",
  CNY: "¥",
  COP: "$",
  CDF: "FC",
  CRC: "₡",
  CZK: "Kč",
  DKK: "kr.",
  DOP: "RD$",
  XCD: "$",
  EGP: "£",
  ETB: "ብር",
  EUR: "€",
  FJD: "$",
  GEL: "ლ",
  GTQ: "Q",
  GNF: "Fr",
  GYD: "$",
  HTG: "G",
  HNL: "L",
  HKD: "HK$",
  HUF: "Ft",
  ISK: "kr",
  INR: "₹",
  IDR: "Rp",
  IQD: "IQD",
  ILS: "₪",
  JMD: "$",
  JPY: "¥",
  JOD: "JOD",
  KZT: "KZT",
  KES: "Sh",
  KWD: "د.ك",
  KGS: "С̱",
  LAK: "₭",
  LBP: "£",
  LSL: "L",
  LRD: "$",
  LYD: "LD",
  MOP: "MOP",
  MKD: "MKD",
  MGA: "Ar",
  MYR: "RM",
  MVR: "Rf",
  MRU: "UM",
  MUR: "₨",
  MXN: "$",
  MDL: "L",
  MNT: "₮",
  MAD: "MAD",
  MZN: "MT",
  NAD: "$",
  NPR: "₨",
  ANG: "ƒ",
  GHS: "GHS",
  TWD: "NT$",
  NZD: "$",
  NIO: "C$",
  NGN: "₦",
  NOK: "kr",
  OMR: "OMR",
  PKR: "₨",
  PAB: "B/.",
  PGK: "PGK",
  PYG: "₲",
  PEN: "S/",
  PHP: "₱",
  PLN: "zł",
  QAR: "QAR",
  RON: "lei",
  SHP: "£",
  SAR: "SAR",
  RSD: "din",
  SCR: "₨",
  SGD: "S$",
  SBD: "$",
  ZAR: "R",
  KRW: "₩",
  LKR: "Rs",
  SDG: "SDP",
  SRD: "$",
  SZL: "SZL",
  SEK: "kr",
  CHF: "CHF",
  SYP: "£",
  TJS: "SM",
  TZS: "TSh",
  THB: "฿",
  TTD: "$",
  TND: "TND",
  TRY: "₺",
  TVD: "$",
  AED: "AED",
  UGX: "USh",
  UAH: "₴",
  USD: "$",
  UYU: "$U",
  UZS: "UZS",
  VUV: "Vt",
  VND: "₫",
  YER: "YER",
  ZMW: "ZK",
};

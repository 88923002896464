import moment from "moment";

import { ComparatorResult } from "shared/types";

import { DateTimeFormat } from "./dateTimeFormat";
import { capitalize } from "./functions";

export const countriesWithUSFormat = ["us", "cw", "jp"];
export const countriesWithLongMonthFormat = ["ph"];

function addLeadingZero(x: string | number): string {
  return `0${x}`.slice(-2);
}

function removeLeadingZero(x: string): string {
  return x.replace(/^0/, "");
}

function manageDateFormat<T>(
  country: string,
  defaultFormat: T,
  usFormat: T,
  longMonthFormat: T
): T {
  const countryUSFormatMapping = countriesWithUSFormat.reduce((acc, curr) => {
    return { ...acc, [curr]: usFormat };
  }, {});

  const countryLongMonthFormatMapping = countriesWithLongMonthFormat.reduce(
    (acc, curr) => {
      return { ...acc, [curr]: longMonthFormat };
    },
    {}
  );
  return (
    countryUSFormatMapping[country?.toLowerCase()] ||
    countryLongMonthFormatMapping[country?.toLowerCase()] ||
    defaultFormat
  );
}

export function dayMonthFormat(country: string): string {
  return manageDateFormat(country, "DD.MM.", "M/D", "DD.MMM.");
}

export function dayMonthYearFormat(country: string, time = false): string {
  return (
    manageDateFormat(country, "DD.MM.YYYY", "M/D/YYYY", "DD.MMM.YY") +
    (time ? ", HH:mm" : "")
  );
}

export function dayMonthYearFormatOptions(country: string): [string, object] {
  const defaultFormat = [
    DateTimeFormat.DAY_MONTH_YEAR_FORMAT_CZ,
    DateTimeFormat.DAY_MONTH_YEAR_FORMAT_CZ_OPTIONS,
  ];
  const usFormat = [
    DateTimeFormat.DAY_MONTH_YEAR_FORMAT_US,
    DateTimeFormat.DAY_MONTH_YEAR_FORMAT_US_OPTIONS,
  ];
  // @ts-ignore
  return manageDateFormat(country, defaultFormat, usFormat);
}

export function hourMinuteFormat(country: string): string {
  return manageDateFormat(country, "HH:mm", "h:mm a", "HH:mm");
}

export function dateComparator(
  a: string,
  b: string,
  format = ""
): ComparatorResult {
  return moment(a, format).isBefore(moment(b, format)) ? -1 : 1;
}

export function formatCZDayMonth(dt) {
  return dt.format("DD.MM.");
}

export function isUtc(): boolean {
  const epochTime = "1970-01-01T00:00:00.000";
  const epochUtcTime = "1970-01-01T00:00:00.000Z";
  return new Date(epochTime).toISOString() === epochUtcTime;
}

export function formatDate(
  date: string | Date,
  languageCode: string,
  format: string,
  options: object
): string {
  let languageCodeTemp = languageCode;

  if (!Intl.DateTimeFormat.supportedLocalesOf([languageCodeTemp]))
    languageCodeTemp = "en";

  const timeZoneOptions = isUtc() ? { timeZone: "UTC" } : {};
  const formatter = new Intl.DateTimeFormat(languageCodeTemp, {
    hourCycle: "h23",
    ...timeZoneOptions,
    ...options,
  });

  const dateParts = formatter
    .formatToParts(new Date(date))
    .filter((part) => part.type !== "literal");

  let modifiedDateParts = dateParts;
  if ("hour" in options && options.hour === "2-digit") {
    modifiedDateParts = dateParts.map((part) => {
      if (part.type === "hour" && part.value.length === 1) {
        return { type: part.type, value: `0${part.value}` };
      }
      return part;
    });
  }

  function byType(parts, type) {
    const part = parts.find((it) => it.type === type);
    return part?.value ? part.value : "";
  }

  return format
    .split(/\b/)
    .map((w) => byType(modifiedDateParts, w) || w)
    .join("");
}

export function weekday(
  date: string | Date,
  locale: string,
  format: "short" | "long" = "short"
): string {
  return capitalize(new Date(date).toLocaleString(locale, { weekday: format }));
}

export function dayMonthYear(
  date: string,
  country: string,
  locale: string
): string {
  return toDayMonthYear(date, country, locale);
}

export function dayMonth(
  date: string,
  country: string,
  locale: string
): string {
  return toDayMonth(date, country, locale);
}

export function hourMinute(isoDatetime: string, country: string): string {
  return toHourMinute(isoDatetime, country);
}

export function monthYear(date: string | Date, locale: string): string {
  if (!date || !locale) {
    return "";
  }
  const d = new Date(date);
  const year = d.getFullYear();
  const month = capitalize(
    d.toLocaleString(locale, {
      month: "long",
    })
  );
  return `${month} ${year}`;
}

export const toDayMonthYearTime = (
  date: string,
  country: string,
  locale: string
): string => {
  const datePart = toDayMonthYear(date, country, locale);
  const timePart = toHourMinute(date, country);

  return `${datePart}, ${timePart}`;
};

export function toDayMonthYear(
  date: string,
  country: string,
  locale: string
): string {
  //  YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss.sssZ
  if (!date?.includes("-")) return "";

  const dayMonthYearPart = date.includes("T") ? date.split("T")[0] : date;

  if (countriesWithLongMonthFormat.includes(country?.toLowerCase())) {
    const longMonthOnly = new Date(dayMonthYearPart).toLocaleString(locale, {
      month: "short",
    });
    const longMonthFormat = moment(dayMonthYearPart).format("DD.MMM.YY");
    const [day, , year] = longMonthFormat.split(".");
    return `${day}.${longMonthOnly}.${year}`;
  }

  const [Y, M, D] = dayMonthYearPart.split("-");

  if (countriesWithUSFormat.includes(country?.toLowerCase())) {
    return `${removeLeadingZero(M)}/${removeLeadingZero(D)}/${Y}`;
  }

  return `${addLeadingZero(D)}.${addLeadingZero(M)}.${Y}`;
}

export function toDayMonth(
  date: string,
  country: string,
  locale: string
): string {
  //  YYYY-MM-DD or YYYY-MM-DDTHH:mm:ss.sssZ
  if (!date?.includes("-")) return "";

  const dayMonthYearPart = date.includes("T") ? date.split("T")[0] : date;

  if (countriesWithLongMonthFormat.includes(country?.toLowerCase())) {
    const longMonthOnly = new Date(dayMonthYearPart).toLocaleString(locale, {
      month: "short",
    });
    const longMonthFormat = moment(dayMonthYearPart).format("DD.MMM.YY");
    const [day, ,] = longMonthFormat.split(".");
    return `${day}.${longMonthOnly}.`;
  }

  const [, M, D] = dayMonthYearPart.split("-");

  if (countriesWithUSFormat.includes(country?.toLowerCase())) {
    return `${removeLeadingZero(M)}/${removeLeadingZero(D)}`;
  }

  return `${addLeadingZero(D)}.${addLeadingZero(M)}`;
}

export function toHourMinute(date: string, country: string): string {
  //  YYYY-MM-DDTHH:mm:ss.sssZ
  const isValidIsoDate = date && date.includes(":") && date.includes("T");
  if (!isValidIsoDate) return "";

  const isUsFormat = countriesWithUSFormat.includes(country?.toLowerCase());

  const timePart = date.split("T")[1];
  const [h, m] = timePart.split(":");

  let hours = Number(removeLeadingZero(h));
  let period = "";

  if (isUsFormat) {
    period = hours >= 12 ? "pm" : "am";
    hours = hours % 12 || 12; // Convert to 12-hour format, with 0 replaced by 12
  }

  const hoursString = String(hours).padStart(2, "0");

  return isUsFormat ? `${hours}:${m} ${period}` : `${hoursString}:${m}`;
}

export const normalizeDate = (date) => {
  const normalized = new Date(date);
  normalized.setHours(0, 0, 0, 0);
  return normalized;
};

export const addDaysToDate = (date, days) => {
  const dateObj = normalizeDate(new Date(date));
  const newDate = new Date(dateObj.getTime() + days * 24 * 60 * 60 * 1000);
  return newDate;
};

import { useRouter } from "next/router";
import React, { ReactElement, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

import { AppState } from "shared/data/reducers";
import { currencies } from "shared/lib/currencies";
import { getCurrency } from "shared/lib/requestorFunctions";

import Img from "@components/FileServer/Img";

import { useOutsideClick } from "@lib/webFunctions";

type ConfigType = {
  currency: string | null;
  alternativeCurrencies: string[];
};

function SetCurrency(): ReactElement {
  const { currency, alternativeCurrencies }: ConfigType = useSelector(
    (state: AppState) => state.requestorConfig
  );
  const [isMenuOpen, toggleMenu] = useState<boolean>(false);
  const [currentCurrency, setCurrentCurrency] = useState<string>(currency);
  const [currencyOptions, setCurrencyOptions] = useState<string[]>([]);

  const ref = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const notShowCurrency = router.pathname.includes("/reservation");

  useOutsideClick(ref, () => {
    if (isMenuOpen) toggleMenu(false);
  });

  useEffect(() => {
    setCurrentCurrency(getCurrency() || currency);
    const newCurrencyOptions = [
      ...new Set([currency, ...alternativeCurrencies]),
    ].filter((it) => it !== (getCurrency() || currency));
    setCurrencyOptions(newCurrencyOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alternativeCurrencies, currency]);

  if (!currencyOptions || currencyOptions.length === 0 || notShowCurrency) {
    return null;
  }

  return (
    <>
      <div className="separator-dotted" />
      <div
        onClick={() => toggleMenu(!isMenuOpen)}
        ref={ref}
        role="button"
        className="header-menu-currencies"
      >
        <span style={{ display: "inline-block", paddingRight: "12px" }}>
          {currencies[currentCurrency.toUpperCase()] || "N/A"}
        </span>
        <span className="header-menu-currencies-selected">
          {currentCurrency.toUpperCase()}
        </span>
        <Img
          src="/static/images/ico-arrow-down.svg"
          className="header-menu-currencies-ico"
          style={{
            transition: "all .4s ease",
            transform: isMenuOpen ? "rotate(180deg)" : null,
          }}
          alt="arrow down icon"
        />
        {isMenuOpen && (
          <div className="header-menu-currencies-tooltip">
            {currencyOptions.map((option) => (
              <a
                key={option}
                onClick={() => changeCurrency(option, currency)}
                role="button"
              >
                <span
                  style={{
                    display: "inline-block",
                    width: "40px",
                  }}
                >
                  {currencies[option.toUpperCase()] || "N/A"}
                </span>
                {option.toUpperCase()}
              </a>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export const changeCurrency = (
  currency: string,
  defaultCurrency: string
): void => {
  const url = window.location.href;
  let newUrl = "";

  const shouldReplaceCurrency =
    url.includes("currency=") && currency !== defaultCurrency;
  const shouldRemoveCurrency =
    url.includes("currency=") && currency === defaultCurrency;
  const shouldAddCurrency =
    !url.includes("currency=") && currency !== defaultCurrency;

  if (shouldReplaceCurrency)
    newUrl = url.replace(
      /([?&])currency=[^&]*(&.*)?/,
      `$1currency=${currency}$2`
    );
  if (shouldRemoveCurrency)
    newUrl = url.replace(/([?&])currency=[^&]*(&.*)?/, `$1$2`);
  if (shouldAddCurrency)
    newUrl = `${url}${url.includes("?") ? "&" : "?"}currency=${currency}`;

  if (url.includes("lowestPrice="))
    newUrl = newUrl.replace(/([?&])lowestPrice=[^&]*(&.*)?/, `$1$2`);
  if (url.includes("highestPrice="))
    newUrl = newUrl.replace(/([?&])highestPrice=[^&]*(&.*)?/, `$1$2`);

  if (newUrl.endsWith("?") || newUrl.endsWith("&")) {
    newUrl = newUrl.replace(/.$/, "");
  }

  window.location.href = newUrl.replace("?&", "?").replace("&&", "&");
};

export default SetCurrency;

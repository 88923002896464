import { ANONYMOUS_CLIENT_TOKEN_EXPIRATION_TIME } from "shared/lib/constants";

import { getPersistentStorage } from "../services/persistentStorageService";
import { Messages } from "./reducers/types";

export const DEVICE_ID = "deviceId";
export const FCM_TOKEN = "fcmToken";
export const AGENCY = "agency";
export const USER = "user";
export const LOCALE = "locale";
export const MENU_ITEMS = "menuItems";
export const FRONTEND_SETTINGS = "frontendSettings";
export const CURRENCY = "currency";
export const RESERVATIONS = "reservations";
export const MESSAGES = "messages";
export const ACTIVE_RESERVATIONS_IDS = "activeReservationsIds";
export const RESERVATION_DETAIL = "reservationDetail";
export const AIRLINES = "airlines";
export const AIRPORTS = "airports";
export const DEFAULT_AIRPORTS = "defaultAirports";
export const COUNTRIES = "countries";

const NUM_MAX_LAST_SEARCH_OPTIONS = 3;
const NUMBER_VALID_MS_CLIENT_TOKEN = 20000;
const persistentSessionStorage = getPersistentStorage("sessionStorage");

export const setClientToken = async (clientToken) => {
  persistentSessionStorage.setItem(
    "clientTokenValidUntil",
    String(+new Date() + NUMBER_VALID_MS_CLIENT_TOKEN)
  );
  return persistentSessionStorage.setItem("token", clientToken);
};

export const setAnonymousToken = async (token) => {
  const clientTokenValidUntil = String(
    +new Date() + ANONYMOUS_CLIENT_TOKEN_EXPIRATION_TIME
  );
  await persistentSessionStorage.setItem("token", token);
  await persistentSessionStorage.setItem(
    "clientTokenValidUntil",
    clientTokenValidUntil
  );
};

export const getClientToken = async () => {
  const token = await persistentSessionStorage.getItem("token");
  return token; // careful, was clientToken, maybe breaks web, test properly
};

export const removeClientToken = async () => {
  return persistentSessionStorage.removeItem("clientToken");
};

export async function getPersistedCbtToken() {
  return persistentSessionStorage.getItem("cbtToken");
}

export async function saveCbtToken(cbtToken) {
  await persistentSessionStorage.setItem("cbtToken", cbtToken);
}

export async function getFcmToken() {
  return persistentSessionStorage.getItem(FCM_TOKEN);
}

export async function setFcmToken(token: string) {
  if (!token) return false;
  await persistentSessionStorage.setItem(FCM_TOKEN, token);
}

export async function removeFcmToken() {
  return persistentSessionStorage.removeItem(FCM_TOKEN);
}

export async function getDeviceId() {
  return persistentSessionStorage.getItem(DEVICE_ID);
}

export async function setDeviceId(deviceId: string) {
  if (!deviceId) return false;
  await persistentSessionStorage.setItem(DEVICE_ID, deviceId);
}

// logging in in web
export const isClientTokenValid = async () => {
  return false;
  // TODO mobile app - check all tokens settings before release
  // const clientTokenValidUntil = await persistentSessionStorage.getItem(
  //   "clientTokenValidUntil"
  // );
  // const clientToken = await persistentSessionStorage.getItem("token");
  //
  // if (!clientToken) {
  //   return false;
  // }
  //
  // if (
  //   !clientTokenValidUntil ||
  //   +new Date() > clientTokenValidUntil + NUMBER_VALID_MS_CLIENT_TOKEN
  // ) {
  //   return false;
  // }
  // return true;
};

// saving reservation info for usage after payment with credit card returns something (because it cannot use url params for passing this info easier)
export const setReservationsInfo = async (reservationsInfo) => {
  persistentSessionStorage.setItem(
    "reservationsInfo",
    JSON.stringify(reservationsInfo)
  );
  return true;
};

export const getReservationsInfo = async () => {
  const reservationsInfoRaw = await persistentSessionStorage.getItem(
    "reservationsInfo"
  );
  if (!reservationsInfoRaw) {
    return false;
  }
  const reservationsInfo = JSON.parse(reservationsInfoRaw);
  return reservationsInfo;
};

export const getLastSearchDestinations = async () => {
  const result = await persistentSessionStorage.getItem(
    "lastSearchDestinations"
  );
  return result;
};

export const setLastSearchDestination = async (option) => {
  const alreadySetLastSearchDestinations = await getLastSearchDestinations();
  if (alreadySetLastSearchDestinations === null) {
    await persistentSessionStorage.setItem(
      "lastSearchDestinations",
      JSON.stringify([option])
    );
  } else {
    const alreadySetLastSearchDestinationsArray = JSON.parse(
      alreadySetLastSearchDestinations
    );

    await persistentSessionStorage.setItem(
      "lastSearchDestinations",
      JSON.stringify(
        [option]
          .concat(
            alreadySetLastSearchDestinationsArray.filter(
              (o) => o.value !== option.value
            )
          )
          .filter((o, i) => i < NUM_MAX_LAST_SEARCH_OPTIONS)
      )
    );
  }
};

export const setUser = async (user) => {
  await getPersistentStorage().setItem(USER, JSON.stringify(user));
  return true;
};

export const getUser = async () => {
  const user = await getPersistentStorage().getItem(USER);
  if (!user) {
    return false;
  }
  return JSON.parse(user);
};

export const removeUser = async () => {
  await getPersistentStorage().removeItem(USER);
  await removeCurrency();
  await removeReservations();
  const activeReservations = await getActiveReservationsIds();
  if (activeReservations) {
    activeReservations.forEach((reservationId) => {
      removeActiveReservationDetail(reservationId);
    });
  }
};

export const setAgency = async (agency) => {
  await getPersistentStorage().setItem(AGENCY, JSON.stringify(agency));
  return true;
};

export const getAgency = async () => {
  const agency = await getPersistentStorage().getItem(AGENCY);
  if (!agency) {
    return false;
  }
  return JSON.parse(agency);
};

export const setReservations = async (reservations) => {
  if (!reservations) {
    return false;
  }
  await getPersistentStorage().setItem(
    RESERVATIONS,
    JSON.stringify(reservations)
  );
  return true;
};

export const setMessages = async (messages: Messages): Promise<boolean> => {
  if (!messages) {
    return false;
  }
  await getPersistentStorage().setItem(MESSAGES, JSON.stringify(messages));
  return true;
};

export const getMessages = async (): Promise<Messages> => {
  const messages = await getPersistentStorage().getItem(MESSAGES);
  if (!messages) {
    return [];
  }
  return JSON.parse(messages);
};

export const getReservations = async () => {
  const reservations = await getPersistentStorage().getItem(RESERVATIONS);
  if (!reservations) {
    return false;
  }
  return JSON.parse(reservations);
};

export const removeReservations = async () => {
  return getPersistentStorage().removeItem(RESERVATIONS);
};

export const setCurrency = async (currency) => {
  if (!currency) {
    return false;
  }
  await getPersistentStorage().setItem(CURRENCY, JSON.stringify(currency));
  return true;
};

export const getCurrency = async () => {
  const currency = await getPersistentStorage().getItem(CURRENCY);
  if (!currency) {
    return false;
  }
  return JSON.parse(currency);
};

export const removeCurrency = async () => {
  return getPersistentStorage().removeItem(CURRENCY);
};

export const saveActiveReservationId = async (reservationId) => {
  const reservationIds = await getActiveReservationsIds();
  if (!reservationIds) {
    await getPersistentStorage().setItem(
      ACTIVE_RESERVATIONS_IDS,
      JSON.stringify([reservationId])
    );
  } else {
    reservationIds.push(reservationId);
    await getPersistentStorage().setItem(
      ACTIVE_RESERVATIONS_IDS,
      JSON.stringify(reservationIds)
    );
  }
  return true;
};

export const getActiveReservationsIds = async () => {
  const reservationIds = await getPersistentStorage().getItem(
    ACTIVE_RESERVATIONS_IDS
  );
  if (!reservationIds) {
    return false;
  }
  return JSON.parse(reservationIds);
};

export const removeActiveReservationsIds = async () => {
  const savedIds = await getActiveReservationsIds();
  if (savedIds && savedIds.length > 0) {
    savedIds.forEach((id) => {
      removeActiveReservationDetail(id);
    });
  }
  return getPersistentStorage().removeItem(ACTIVE_RESERVATIONS_IDS);
};

export const setActiveReservationDetail = async (
  reservationId,
  reservationDetail
) => {
  await getPersistentStorage().setItem(
    `${RESERVATION_DETAIL}-${reservationId}`,
    JSON.stringify(reservationDetail)
  );
  return true;
};

export const getActiveReservationDetail = async (reservationId) => {
  const reservationDetail = await getPersistentStorage().getItem(
    `${RESERVATION_DETAIL}-${reservationId}`
  );
  if (!reservationDetail) {
    return false;
  }
  return JSON.parse(reservationDetail);
};

export const removeActiveReservationDetail = async (reservationId) => {
  return getPersistentStorage().removeItem(
    `${RESERVATION_DETAIL}-${reservationId}`
  );
};

export const setMenuItems = async (menuItems) => {
  await getPersistentStorage().setItem(MENU_ITEMS, JSON.stringify(menuItems));
  return true;
};

export const getMenuItems = async () => {
  const menuItems = await getPersistentStorage().getItem(MENU_ITEMS);
  if (!menuItems) {
    return false;
  }
  return JSON.parse(menuItems);
};

export const setFrontendSetting = async (frontendSettings) => {
  await getPersistentStorage().setItem(
    FRONTEND_SETTINGS,
    JSON.stringify(frontendSettings)
  );
  return true;
};

export const getFrontendSetting = async () => {
  const frontendSettings = await getPersistentStorage().getItem(
    FRONTEND_SETTINGS
  );
  if (!frontendSettings) {
    return false;
  }
  return JSON.parse(frontendSettings);
};

export const setLanguage = async (locale) => {
  if (!locale) {
    return false;
  }
  await getPersistentStorage().setItem(LOCALE, locale.toString());
  return true;
};

export const getLanguage = async () => {
  const locale = await getPersistentStorage().getItem(LOCALE);
  if (!locale) {
    return false;
  }
  return locale;
};

export const setLocale = async (locale) => {
  if (!locale) {
    return false;
  }
  await getPersistentStorage().setItem(LOCALE, locale.toString());
  return true;
};

export const getLocale = async () => {
  const locale = await getPersistentStorage().getItem(LOCALE);
  if (!locale) {
    return false;
  }
  return locale;
};

export const setDefaultAirports = async (defaultAirports) => {
  await getPersistentStorage().setItem(
    DEFAULT_AIRPORTS,
    JSON.stringify(defaultAirports)
  );
  return true;
};

export const getDefaultAirports = async () => {
  const defaultAirports = await getPersistentStorage().getItem(
    DEFAULT_AIRPORTS
  );
  if (!defaultAirports) {
    return false;
  }
  return JSON.parse(defaultAirports);
};

export const setAirports = async (airports) => {
  await getPersistentStorage().setItem(AIRPORTS, JSON.stringify(airports));
  return true;
};

export const getAirports = async () => {
  const airports = await getPersistentStorage().getItem(AIRPORTS);
  if (!airports) {
    return false;
  }
  return JSON.parse(airports);
};

export const setAirlines = async (airlines) => {
  await getPersistentStorage().setItem(AIRLINES, JSON.stringify(airlines));
  return true;
};

export const getAirlines = async () => {
  const airlines = await getPersistentStorage().getItem(AIRLINES);
  if (!airlines) {
    return false;
  }
  return JSON.parse(airlines);
};

export const setCountries = async (countries) => {
  await getPersistentStorage().setItem(COUNTRIES, JSON.stringify(countries));
  return true;
};

export const getCountries = async () => {
  const countries = await getPersistentStorage().getItem(COUNTRIES);
  if (!countries) {
    return false;
  }
  return JSON.parse(countries);
};

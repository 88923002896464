import getCbtClient, { cbtResponse } from "../cbtClient";

export async function listTravelReasons({
  cbtToken,
  customerUsername,
  cbtApiUrl,
}) {
  if (!cbtToken) return;

  const cbtClient = await getCbtClient({
    cbtToken,
    customerUsername,
    cbtApiUrl,
  });

  return await cbtResponse(cbtClient.get(`/travel-reasons`));
}

/* eslint-disable no-console */

type AsyncFunction<TArgs, TResult> = (args: TArgs) => Promise<TResult>;

export const timeBenchmark = async <TArgs, TResult>(
  fn: AsyncFunction<TArgs, TResult>,
  args: TArgs,
  label = "Execution Time"
): Promise<TResult> => {
  const startTime = Date.now();

  try {
    const result = await fn(args);
    const endTime = Date.now();
    const elapsedTime = endTime - startTime;

    console.log(
      `Time benchmark - ${label} - Completed at ${new Date(
        endTime
      ).toISOString()}, ${elapsedTime}ms`
    );

    return result;
  } catch (error) {
    const endTime = Date.now();
    const elapsedTime = endTime - startTime;

    console.error(
      `Time benchmark - ${label} - Failed at ${new Date(
        endTime
      ).toISOString()}, ${elapsedTime}ms`
    );
    throw error;
  }
};

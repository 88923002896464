import { CSSProperties, ReactNode, useState } from "react";

import FormattedMessage from "@components/UI/FormattedMessage";
import Modal from "@components/UI/Modal";

type ButtonProps = {
  label: string;
  onClick?: () => void;
};

type ModalComponentProps = {
  show: boolean;
  onClose: () => void;
  message?: string;
  hideHeader?: boolean;
  customButtons?: ButtonProps | ButtonProps[];
  disableOutsideClose?: boolean;
  customContent?: ReactNode | ReactNode[];
  isCloseButtonHidden?: boolean;
  hideButtons?: boolean;
  customBodyStyles?: CSSProperties;
  title?: string;
  customTitleClass?: string;
};

const ModalComponent = ({
  show,
  onClose,
  message,
  hideHeader,
  customButtons,
  disableOutsideClose,
  isCloseButtonHidden = true,
  customContent,
  customBodyStyles = {},
  hideButtons = false,
  title,
  customTitleClass = "",
}: ModalComponentProps) => {
  const ButtonTemplate = ({ onClick, label }: ButtonProps) => (
    <div
      role="button"
      id="modal-alert-button"
      onClick={onClick}
      className="modal-alert-button"
    >
      {label}
    </div>
  );

  const defaultButtons = [
    <ButtonTemplate key={0} onClick={onClose} label="Ok" />,
  ];

  const buttons = customButtons
    ? Array.isArray(customButtons)
      ? customButtons.map((button, index) => (
          <ButtonTemplate key={index} {...button} />
        ))
      : [<ButtonTemplate key={0} {...customButtons} />]
    : defaultButtons;

  return (
    <Modal
      isActive={show}
      onClose={onClose}
      isCloseButtonHidden={isCloseButtonHidden}
      disableOutsideClickClose={!disableOutsideClose}
    >
      <div style={customBodyStyles} className="modal-alert">
        <div className="modal-alert-label">
          {!hideHeader && (
            <div className={customTitleClass}>
              {title || <FormattedMessage id="Alert.label.error" />}
            </div>
          )}
        </div>
        <div className="modal-alert-content">
          {customContent || message || "Undefined content"}
        </div>
        {hideButtons === false && (
          <div className="modal-alert-buttons">{buttons}</div>
        )}
      </div>
    </Modal>
  );
};

type Props = {
  onClose?: () => void;
  hideHeader?: boolean;
  customTitle?: string;
  customButtons?: ButtonProps | ButtonProps[];
  disableOutsideClose?: boolean;
};

const useModalAlert = (props?: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const [isCallbackDisabled, setIsCallbackDisabled] = useState<boolean>(false);
  const [message, setMessage] = useState<string>(null);
  const [onClose, setOnClose] = useState<() => () => void>(() => () => {});

  const showError = (
    errorMessage: string,
    disableCallback = false,
    onErrorClose?: () => void
  ) => {
    setMessage(errorMessage);
    setShow(true);
    setIsCallbackDisabled(disableCallback);
    if (onErrorClose) {
      setOnClose(() => onErrorClose);
    }
  };

  const onModalClose = () => {
    setShow(false);
    setMessage(null);
    if (props?.onClose && !isCallbackDisabled) {
      props?.onClose();
    }
    onClose();
  };

  const Component = () => (
    <ModalComponent
      show={show}
      onClose={onModalClose}
      message={message}
      hideHeader={props?.hideHeader}
      customTitle={props?.customTitle}
      customButtons={props?.customButtons}
      disableOutsideClose={props?.disableOutsideClose}
    />
  );

  return [Component, showError];
};

type LegacyModalAlertProps = {
  show: boolean;
  onClose: () => void;

  message?: string;
  hideHeader?: boolean;
  customButtons?: ButtonProps | ButtonProps[];
  disableOutsideClose?: boolean;
  hideButtons?: boolean;
  isCloseButtonHidden?: boolean;
  customContent?: ReactNode | ReactNode[];
  customBodyStyles?: CSSProperties;
  customTitleClass?: string;
  title?: string;
};

export const LegacyModalAlert = ({
  show,
  onClose,
  message,
  hideHeader,
  customButtons,
  disableOutsideClose,
  customContent,
  customBodyStyles,
  isCloseButtonHidden,
  hideButtons,
  customTitleClass,
  title,
}: LegacyModalAlertProps) => {
  return (
    <ModalComponent
      show={show}
      onClose={onClose}
      title={title}
      customTitleClass={customTitleClass}
      isCloseButtonHidden={isCloseButtonHidden}
      hideButtons={hideButtons}
      message={message}
      hideHeader={hideHeader}
      customButtons={customButtons}
      disableOutsideClose={disableOutsideClose}
      customContent={customContent}
      customBodyStyles={customBodyStyles}
    />
  );
};

export default useModalAlert;

import getCbtClient, { cbtResponse } from "../cbtClient";
import { CbtConnectionData } from "../cbtConnectionData";

interface ManagersResponseData {
  Id: string;
  CorporateId: string;
  Email: string;
  FirstName: string;
  LastName: string;
  FirstNameLatin: string;
  LastNameLatin: string;
  NamePrefix: string;
  BirthDate: string;
  PhoneNumber: string;
  FrequentFlyers: Array<any>;
  Passports: Array<any>;
  Manager: boolean;
  Traveller: boolean;
  TravelArranger: boolean;
  SoloTraveller: boolean;
  Guest: boolean;
  CorpoAdmin: boolean;
  GradeId: string;
  CostCenterIds: Array<any>;
  PolicyId: string;
  ManagerIds: Array<any>;
  ExternalId: string;
  GolAgencyId: string;
  GolAgencyName: string;
  OwnerId: string;
  Share: boolean;
  DelegateIds: Array<any>;
  DelegationDate: object;
  Active: boolean;
  Budget: object;
  AllowManagerAccessToTcp: boolean;
}

const managersPath = "/managers?limit=1000";

const listManagers = async (
  cbtConnectionData: CbtConnectionData
): Promise<{
  success: boolean;
  data?: ManagersResponseData[];
  errorMsg?: any;
  errorCode?: any;
}> => {
  const cbtClient = await getCbtClient(cbtConnectionData);

  return await cbtResponse(cbtClient.get(managersPath));
};

export { listManagers };

import React from "react";

import { initializeStore } from "../store";

const isServer = typeof window === "undefined";
const __NEXT_REDUX_STORE__ = "__NEXT_REDUX_STORE__";

function getOrCreateStore(initialState) {
  if (isServer) {
    return initializeStore(initialState);
  }

  if (!window[`${__NEXT_REDUX_STORE__}`]) {
    window[`${__NEXT_REDUX_STORE__}`] = initializeStore(initialState);
  }
  return window[`${__NEXT_REDUX_STORE__}`];
}

export default (App) => {
  const AppWithRedux = (props) => {
    const reduxStore = React.useMemo(
      () => getOrCreateStore(props.initialReduxState),
      [props.initialReduxState]
    );
    return <App {...props} reduxStore={reduxStore} />;
  };

  AppWithRedux.getInitialProps = async (appContext) => {
    const reduxStore = getOrCreateStore();

    // eslint-disable-next-line no-param-reassign
    appContext.ctx.reduxStore = reduxStore;

    let appProps = {};
    if (typeof App.getInitialProps === "function") {
      appProps = await App.getInitialProps(appContext);
    }

    return {
      ...appProps,
      initialReduxState: reduxStore.getState(),
    };
  };

  return AppWithRedux;
};

import React from "react";
import { useSelector } from "react-redux";

import { AppState } from "shared/data/reducers";
import { currencies } from "shared/lib/currencies";

import { changeCurrency } from "./SetCurrency";

type ConfigType = {
  currency: string | null;
  alternativeCurrencies: string[];
};

function SetCurrency() {
  const { currency, alternativeCurrencies }: ConfigType = useSelector(
    (state: AppState) => state.requestorConfig
  );

  return [...new Set([currency, ...alternativeCurrencies])].map((c) => (
    <a
      key={c}
      href="#"
      className="header-mobile-menu-link"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        changeCurrency(c, currency);
      }}
    >
      <span
        style={{
          display: "inline-block",
          width: "50px",
          textAlign: "left",
        }}
      >
        {currencies[c.toUpperCase()] || "N/A"}
      </span>
      {c.toUpperCase()}
    </a>
  ));
}

export default SetCurrency;

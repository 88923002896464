import getCbtClient, { cbtResponse } from "../cbtClient";

export async function listGrades({ cbtToken, customerUsername, cbtApiUrl }) {
  if (!cbtToken) return;

  const cbtClient = await getCbtClient({
    cbtToken,
    customerUsername,
    cbtApiUrl,
  });

  return await cbtResponse(cbtClient.get(`/grades`));
}

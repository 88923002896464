export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  FORGOTTEN_PASSWORD: "/forgottenPassword",
  RESERVATIONS: "/reservations",
  RESERVATION: "/reservation",
  PAGE: "/page",
  NONE: "#",
  PROFILE: "/profile",
  REGISTER: "/register",
  BOOKED: "/booked",
  NOT_FOUND: "/404",
  BEST_OFFER_RESULTS: "/bestOfferResults",
  BEST_OFFERS: "/bestOffers",
  CREATE_NEW_PASSWORD: "/create-new-password",
  BOOKED_HOTEL: "/bookedHotel",
  HOTEL: "/hotel",
  HOTELS: "/hotels",
  REDIRECT_TO_BOOKED: "/redirectToBooked",
  RESULT: "/result",
  RESULTS: "/results",
  IFRAME: "/iframe",
  BOOK_HOTEL: "/bookHotel",
  EMAIL_CONFIRM: "/emailconfirm",
  LOW_COST_SEARCH: "/lowCostSearch",
};

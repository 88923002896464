import classnames from "classnames";
import { SingletonRouter, withRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { IntlShape, defineMessages } from "react-intl";

import { countPassengers } from "shared/lib/functions";
import { PassengersCount } from "shared/lib/golObjectTypes/PassengerTypes";
import {
  isPassengersSelectError,
  setPassengersSelectErrorMessage,
} from "shared/lib/passengersSelect/isPassengersSelectError";
import { formattedMessageParams } from "shared/messages";

import Img from "@components/FileServer/Img";
import PassengersSelect from "@components/SearchForm/PassengersSelect";

import {
  checkIsFlightSearchForm,
  checkIsHotelSearchForm,
} from "@lib/webFunctions";

const HEIGHT_FROM_TOP = 350;
const HEIGHT_FROM_BOTTOM = 340;
const EXPORT_HTML_PACKAGE = Boolean(process.env.NEXT_PUBLIC_EXPORT_PACKAGE);

const {
  PropertiesSelectOnePassenger,
  PropertiesSelectTwoThreePassengers,
  PropertiesSelectFourPlusPassengers,
  PropertiesSelectClassEconomy,
  PropertiesSelectClassPremiumEconomy,
  PropertiesSelectClassBusiness,
  PropertiesSelectClassFirstClass,
} = defineMessages({
  PropertiesSelectOnePassenger: formattedMessageParams(
    "PropertiesSelect.OnePassenger"
  ),
  PropertiesSelectTwoThreePassengers: formattedMessageParams(
    "PropertiesSelect.TwoThreePassengers"
  ),
  PropertiesSelectFourPlusPassengers: formattedMessageParams(
    "PropertiesSelect.FourPlusPassengers"
  ),
  PropertiesSelectClassEconomy: formattedMessageParams(
    "SearchForm.classEconomy"
  ),
  PropertiesSelectClassPremiumEconomy: formattedMessageParams(
    "SearchForm.classPremiumEconomy"
  ),
  PropertiesSelectClassBusiness: formattedMessageParams(
    "SearchForm.classBusiness"
  ),
  PropertiesSelectClassFirstClass: formattedMessageParams(
    "SearchForm.classFirstClass"
  ),
});

export function getFlightClasses(
  intl: IntlShape
): Array<{ value: string; label: string }> {
  return [
    {
      value: "ECO",
      label: intl.formatMessage(PropertiesSelectClassEconomy).toUpperCase(),
    },
    {
      value: "PRE",
      label: intl
        .formatMessage(PropertiesSelectClassPremiumEconomy)
        .toUpperCase(),
    },
    {
      value: "BUS",
      label: intl.formatMessage(PropertiesSelectClassBusiness).toUpperCase(),
    },
    {
      value: "1ST",
      label: intl.formatMessage(PropertiesSelectClassFirstClass).toUpperCase(),
    },
  ];
}

interface PropertiesSelectProps {
  intl: IntlShape;
  passengers: PassengersCount;
  rooms?: number;
  flightClass?: string;
  onChangeFlightClass?: (flightClass: string) => void;
  defaultFlightClassName?: string;
  onAddCounterHandler: (type: string) => void;
  onRemoveCounterHandler: (type: string) => void;
  router: SingletonRouter;
  isBestOfferSelect?: boolean;
  selectComponentPrefix: string;
  maxNumPassengers: number;
  resetPassengers?: () => void;
}

interface ErrorState {
  error: boolean;
  errorMessage: number | null;
}

const PropertiesSelect: React.FC<PropertiesSelectProps> = ({
  intl,
  passengers,
  flightClass,
  onChangeFlightClass,
  defaultFlightClassName,
  onAddCounterHandler: propOnAddCounterHandler,
  onRemoveCounterHandler: propOnRemoveCounterHandler,
  router,
  isBestOfferSelect = false,
  selectComponentPrefix,
  maxNumPassengers,
  resetPassengers,
}) => {
  const [showProperties, setShowProperties] = useState<boolean>(
    EXPORT_HTML_PACKAGE ?? false
  );
  const [errorState, setErrorState] = useState<ErrorState>({
    error: false,
    errorMessage: null,
  });
  const [isOnTop, setIsOnTop] = useState<boolean>(false);

  const setErrorMessage = (messageNumber: number): void => {
    setErrorState({
      error: true,
      errorMessage: messageNumber,
    });
  };

  const clearErrorMessage = (): void => {
    setErrorState({
      error: false,
      errorMessage: null,
    });
  };

  const onAddCounterHandler = (type: string): void => {
    if (!isPassengersSelectError(passengers)) {
      clearErrorMessage();
    }
    propOnAddCounterHandler(type);
  };

  const onRemoveCounterHandler = (type: string): void => {
    if (!isPassengersSelectError(passengers)) {
      clearErrorMessage();
    }
    propOnRemoveCounterHandler(type);
  };

  const handleClickOutside = useCallback(
    (event: MouseEvent): void => {
      if (!isPassengersSelectError(passengers)) {
        clearErrorMessage();
      }

      if ((event.target as HTMLElement).className === "loader-wrapper-mini") {
        if (setPassengersSelectErrorMessage(passengers, setErrorMessage)) {
          return;
        }
        setShowProperties(false);
      }
    },
    [passengers]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  useEffect(() => {
    const currentIsHotelSearchForm =
      !isBestOfferSelect && checkIsHotelSearchForm(router);

    if (
      resetPassengers &&
      currentIsHotelSearchForm &&
      !checkIsFlightSearchForm(router)
    ) {
      resetPassengers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBestOfferSelect, router]);

  const isVisible = (): void => {
    const el = document.querySelector(
      `#${selectComponentPrefix}-div_toggle_properties`
    );

    if (el) {
      // Get it's position in the viewport
      const bounding = el.getBoundingClientRect();
      if (
        bounding.top < HEIGHT_FROM_TOP ||
        bounding.bottom + HEIGHT_FROM_BOTTOM <=
          (window.innerHeight || document.documentElement.clientHeight)
      ) {
        setIsOnTop(false);
      } else {
        setIsOnTop(true);
      }
    }
  };

  const toggleShowProperties = (): void => {
    if (
      setPassengersSelectErrorMessage(passengers, setErrorMessage) &&
      showProperties
    ) {
      return;
    }
    isVisible();
    setShowProperties(!showProperties);
    setErrorState({
      error: false,
      errorMessage: null,
    });
  };

  const numPassengers = countPassengers(passengers);

  let wordPassengers = intl.formatMessage(PropertiesSelectTwoThreePassengers);
  if (numPassengers === 0 || numPassengers > 4) {
    wordPassengers = intl.formatMessage(PropertiesSelectFourPlusPassengers);
  } else if (numPassengers === 1) {
    wordPassengers = intl.formatMessage(PropertiesSelectOnePassenger);
  }

  const flightClasses = getFlightClasses(intl);
  const valueFlightClass = flightClasses.find(
    (oFlightClass) => oFlightClass.value === flightClass
  );

  const flightClassTranslation = {
    ECO: {
      label: intl.formatMessage(PropertiesSelectClassEconomy).toUpperCase(),
    },
    PRE: {
      label: intl
        .formatMessage(PropertiesSelectClassPremiumEconomy)
        .toUpperCase(),
    },
    BUS: {
      label: intl.formatMessage(PropertiesSelectClassBusiness).toUpperCase(),
    },
    "1ST": {
      label: intl.formatMessage(PropertiesSelectClassFirstClass).toUpperCase(),
    },
  };

  const isHotelSearchForm =
    !isBestOfferSelect && checkIsHotelSearchForm(router);

  return (
    <>
      {showProperties && <div className="loader-wrapper-mini" />}
      <div>
        <div
          className="form-properties-wrapper"
          onClick={toggleShowProperties}
          role="button"
          id={`${selectComponentPrefix}-div_toggle_properties`}
        >
          <span
            className={`${selectComponentPrefix}-inner-field-value nowrap`}
            id={`${selectComponentPrefix}-properties-value`}
          >
            {`${numPassengers} ${wordPassengers}`}
          </span>

          {!isHotelSearchForm && (
            <span
              className={`${selectComponentPrefix}-inner-field-additional nowrap`}
            >
              {flightClass
                ? flightClassTranslation[flightClass]?.label
                : defaultFlightClassName?.toUpperCase()}
            </span>
          )}

          <Img
            src="/static/images/ico-arrow-down.svg"
            id={`${selectComponentPrefix}-properties-arrow`}
            style={{
              padding: "2px",
              marginLeft: "5px",
              transition: "all .4s ease",
              transform: showProperties ? "rotate(180deg)" : null,
            }}
          />
        </div>
        {showProperties && (
          <div
            id={`${selectComponentPrefix}-properties`}
            className={classnames(
              `${selectComponentPrefix}-properties iframe`,
              {
                [`${selectComponentPrefix}-properties-top`]: isOnTop,
              }
            )}
          >
            <PassengersSelect
              isHotelSearchForm={isHotelSearchForm}
              onAddCounterHandler={onAddCounterHandler}
              onChangeFlightClass={onChangeFlightClass}
              onRemoveCounterHandler={onRemoveCounterHandler}
              passengers={passengers}
              flightClasses={flightClasses}
              valueFlightClass={valueFlightClass}
              displayShowToggleProperties
              toggleShowProperties={toggleShowProperties}
              error={errorState.error}
              errorMessage={errorState.errorMessage}
              selectComponentPrefix={selectComponentPrefix}
              allowClassSelection={!isBestOfferSelect}
              defaultFlightClassName={defaultFlightClassName}
              numPassengers={numPassengers}
              maxNumPassengers={maxNumPassengers}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default withRouter(PropertiesSelect);

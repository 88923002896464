import { logoutCustomer } from "shared/data/customerTokens";
import {
  getPersistedCbtToken,
  saveCbtToken,
} from "shared/data/persistentStorage";
import {
  parseAlternativeCurrencyPaymentFormMethods,
  parsePaymentFormMethods,
} from "shared/hooks/paymentDefinition";
import axios from "shared/lib/axios";

import { getBackendID } from "../data/customerTokens";
import Logger from "../services/Logger";
import { createResponse, isFailed } from "./functions";
import { ResponseObject } from "./golObjectTypes/ResponseObject";
import {
  acqusitionParamsRequestObject,
  getBaggageConditionsObject,
  getCreateCustomerRequestObject,
  getCreateCustomerTokenRequestObject,
  getCustomTextRequestObject,
  getCustomerDetailRequestObject,
  getCustomerUpdatePasswordRequestObject,
  getDetailRequestorRequestObject,
  getFareConditionsRequestObject,
  getForgottenPasswordRequestObject,
  getGetParamConditionsObject,
  getLoyaltyProgramsRequestObject,
  getSearchDestinationRequestObject,
  getUpdateCustomerRequestObject,
  priceActualizationRequest,
  reservationRequestObject,
} from "./golRequestObjects";
import { isFalsy } from "./isFalsy";

const sharedConfig = require("../config/sharedConfig");

const NEED_CONFIRMATION_ERROR_CODE = "2126";

export function buildGolRequestForDetailRequestor(options?: {
  forcedCustomerToken?: string;
  forcedRequestorPublicKey?: string;
  forcedClientId?: string;
}) {
  return getDetailRequestorRequestObject(options);
}

export const getFareConditions = async (refs) => {
  const golRequest = await getFareConditionsRequestObject(refs);
  try {
    const response = await axios.post(getGolApiUrl(), golRequest);
    return {
      content:
        response.data.GolApi.ResponseDetail.SearchTextResponse_1.TextContent.$t,
      title:
        response.data.GolApi.ResponseDetail.SearchTextResponse_1.TextTitle.$t,
      alternateUrl:
        response.data.GolApi.ResponseDetail.SearchTextResponse_1.AlternativeUrl
          ?.$t,
    };
  } catch (e) {
    Logger.error(e);
  }
};

export const getCustomText = async (refs) => {
  const golRequest = await getCustomTextRequestObject(refs);
  try {
    const response = await axios.post(getGolApiUrl(), golRequest);
    return {
      content:
        response.data.GolApi.ResponseDetail.SearchTextResponse_1.TextContent.$t,
      title:
        response.data.GolApi.ResponseDetail.SearchTextResponse_1.TextTitle.$t,
      alternateUrl:
        response.data.GolApi.ResponseDetail.SearchTextResponse_1.AlternativeUrl
          ?.$t,
    };
  } catch (e) {
    Logger.error(e);
    return {
      showErrorText: true,
    };
  }
};

export const getLoyaltyPrograms = async () => {
  const golRequest = await getLoyaltyProgramsRequestObject();
  try {
    const response = await axios.post(getGolApiUrl(), golRequest);
    const programs = response.data.GolApi.ResponseDetail.ExportLoyaltyProgramResponse_1.Program.map(
      (program) => ({
        value: program.Code,
        label: program.$t,
      })
    );
    return programs;
  } catch (e) {
    Logger.error(e);
    return false;
  }
};

export async function createCustomer(payload) {
  const golRequest = await getCreateCustomerRequestObject(payload);
  try {
    const response = await axios.post(getGolApiUrl(), golRequest);
    return response.data.GolApi.ResponseDetail;
  } catch (e) {
    Logger.error(e);
    return false;
  }
}

export async function getCustomerToken(payload) {
  const golRequest = await getCreateCustomerTokenRequestObject(payload);
  try {
    const response = await axios.post(getGolApiUrl(), golRequest);
    if (
      response?.data?.GolApi?.ResponseDetail?.CreateCustomerTokenError_1
        ?.Error?.[0]?.Code === NEED_CONFIRMATION_ERROR_CODE
    ) {
      return {
        needsConfirmation: true,
        errorMessage:
          response.data.GolApi.ResponseDetail.CreateCustomerTokenError_1
            .Error[0].$t,
      };
    }

    if (!isFailed(response)) {
      const createCustomerTokenResponse =
        response.data.GolApi.ResponseDetail.CreateCustomerTokenResponse_1;
      return {
        token: createCustomerTokenResponse?.Token?.$t,
        signedToken: createCustomerTokenResponse?.SignedToken?.$t,
        requestorPublicKey: createCustomerTokenResponse?.RequestorPublicKey?.$t,
      };
    }
    return false;
  } catch (e) {
    Logger.error(e);
    return false;
  }
}

export async function getCustomerDetail(payload = {}) {
  const golRequest = await getCustomerDetailRequestObject(payload);

  try {
    const response = await axios.post(getGolApiUrl(), golRequest);

    if (isFailed(response)) {
      Logger.error(response.data.GolApi.ResponseDetail.DetailCustomerError_1);
      if (
        response.data.GolApi.ResponseDetail.DetailCustomerError_1.Error[0]
          .Code === "2001"
      ) {
        await logoutCustomer();
      }
      return false;
    }
    const detailCustomerResponse =
      response.data.GolApi.ResponseDetail.DetailCustomerResponse_1;
    const customerDetail = detailCustomerResponse.CustomerInfo.Person;
    const customerSettings = detailCustomerResponse.CustomerSettings;
    const requestorPrivateSettings =
      detailCustomerResponse.RequestorPrivateSettings;
    const cbtToken =
      requestorPrivateSettings && requestorPrivateSettings.TokenCBT.$t;
    const passengers =
      detailCustomerResponse.CustomerPassengers?.CustomerPassenger || [];
    if (cbtToken && (await !getPersistedCbtToken()))
      await saveCbtToken(cbtToken);

    const normalizePerson = (person) => ({
      email: person.Email.$t,
      firstname: person.GivenName.$t, // for backward compatibility
      givenName: person.GivenName.$t,
      surname: person.Surname.$t,
      telephone: person.Telephone.PhoneNumber,
      namePrefix: person.NamePrefix,
      birthday: person.BirthDate, // for backward compatibility
      birthDate: person.BirthDate,
    });

    return {
      username: detailCustomerResponse.CustomerInfo.Username,
      ...normalizePerson(customerDetail),
      passport: {
        passportNumber: customerSettings.Passport.Number,
        ValidityDate: customerSettings.Passport.ValidityDate,
        Citizenship: customerSettings.Passport.Citizenship,
        Issued: customerSettings.Passport.Issued,
      },
      loyaltyProgram: [...customerSettings.Loyalties.LoyaltyProgram],
      passengers: passengers.map((passenger) => ({
        id: passenger.PassengerId,
        ...normalizePerson(passenger.Person),
      })),
      newsletter: customerSettings.Newsletter.Active === "true",
      specialOffers: customerSettings.SpecialOffers.Active === "true",
      cbtToken,
    };
  } catch (e) {
    Logger.error(e);
    return false;
  }
}

export async function sendForgottenPasswordEmail(payload) {
  const golRequest = await getForgottenPasswordRequestObject(payload);
  try {
    const response = await axios.post(getGolApiUrl(), golRequest);

    if (response.data.GolApi.ResponseDetail.IssueCustomerAuthorizationError_1) {
      return false;
    }
    return true;
  } catch (e) {
    Logger.error(e);
    return false;
  }
}

export async function updateCustomer(payload) {
  const golRequest = await getUpdateCustomerRequestObject(payload);

  try {
    const response = await axios.post(getGolApiUrl(), golRequest);

    return createResponse(response);
  } catch (e) {
    Logger.error(e);
  }
}

export async function updateCustomerPassword(payload): Promise<ResponseObject> {
  const golRequest = await getCustomerUpdatePasswordRequestObject(payload);
  try {
    const response = await axios.post(
      (typeof window !== "undefined" &&
        window?.__ENV?.NEXT_PUBLIC_D4_golApiUrl) ||
        sharedConfig.golApiUrl,
      JSON.stringify(golRequest)
    );
    return createResponse(response);
  } catch (e) {
    Logger.error(e);
  }
}

export const fetchAcquisitionParams = async ({
  refs,
  fullPrice,
  defaultCountry,
  promoCode = false,
  alternativeCurrency,
}) => {
  if (!refs) {
    return false;
  }

  const golRequest = await acqusitionParamsRequestObject({
    refs,
    fullPrice,
    defaultCountry,
    promoCode: !isFalsy(promoCode) ? promoCode : false,
    alternativeCurrency,
  });

  const response = await axios.post(getGolApiUrl(), golRequest);

  const acquisitionParams =
    response.data.GolApi.ResponseDetail.AcquisitionParamsResponse_3;

  if (!acquisitionParams) {
    return false;
  }

  const codeBook = response.data.GolApi.CodeBook;

  const paymentFormMethods = parsePaymentFormMethods(
    acquisitionParams,
    codeBook
  );

  const alternativeCurrencyPaymentFormMethods = parseAlternativeCurrencyPaymentFormMethods(
    acquisitionParams,
    codeBook
  );

  const result = {
    isLoaded: true,
    codebook: codeBook !== undefined ? codeBook : [],
    bookingForm: acquisitionParams.BookingForm,
    bookingFormAlerts: acquisitionParams?.BookingFormAlerts,
    ticketingCombinations:
      acquisitionParams.TicketingCombinations.TicketingCombination,
    paymentFormMethods,
    alternativeCurrencyPaymentFormMethods,
  };

  return result;
};

// used only for flight tickets
export const priceActualization = async ({
  refs,
  promoCode,
  alternativeCurrency,
}) => {
  try {
    const golRequest = await priceActualizationRequest({
      refs,
      promoCode,
      alternativeCurrency,
    });
    const response = await axios.post(getGolApiUrl(), golRequest);

    const golApi = response.data.GolApi;
    const actualFlightOffer =
      golApi.ResponseDetail.PriceActualizationResponse_2?.FlightOffer;

    if (!actualFlightOffer) {
      return false;
    }
    const flightCombinationsWrapper = actualFlightOffer.FlightCombinations;

    let updatedRefs;

    if (flightCombinationsWrapper) {
      const flightCombinations = flightCombinationsWrapper.FlightCombination;
      const selectedFlightCombination = flightCombinations[0];
      const selectedReferences = selectedFlightCombination.References.Reference;
      updatedRefs = selectedReferences.map((ref) => ref.$t);

      const pricingDetail = actualFlightOffer.PricingDetails.PricingDetail[0];
      const flightPassengerPrice =
        pricingDetail.FlightPricing.FlightPassengerPrices.FlightPassengerPrice;
      const flightPrice = pricingDetail.FlightPricing.FlightPrice;

      actualFlightOffer.FlightPassengerPrices = flightPassengerPrice;
      actualFlightOffer.FlightPrice = flightPrice;
    } else {
      // old v1 refs counting when v2 adds it this way
      updatedRefs = actualFlightOffer.FlightItinerary.FlightStream.map(
        (flightStream) =>
          Array.isArray(flightStream.FlightOption[0].Reference)
            ? flightStream.FlightOption[0].Reference[0].$t
            : flightStream.FlightOption[0].Reference.$t
      );
    }

    const newObj = {
      ...actualFlightOffer,
      FlightItinerary: {
        ...actualFlightOffer.FlightItinerary,
        FlightStream: actualFlightOffer.FlightItinerary.FlightStream.map(
          (flightStream) => ({
            ...flightStream,
            FlightOption: flightStream.FlightOption.map((option) => ({
              ...option,
              numTransfers: option.FlightSegments.FlightSegment.length - 1,
            })),
          })
        ),
      },
    };

    return {
      actualFlightOffer: newObj,
      refs: updatedRefs,
      CodeBook: golApi.CodeBook,
      currency: alternativeCurrency || golApi.Settings.Currency.Code,
      fullResponse: golApi.ResponseDetail.PriceActualizationResponse_2,
      exchangeRate: golApi.Settings?.AlternativeCurrency?.ExchangeRate,
    };
  } catch (e) {
    Logger.error(e);
    return false;
  }
};

export const getBaggageConditions = async (
  refs,
  alternativeCurrency
): Promise<ResponseObject> => {
  const golRequest = await getBaggageConditionsObject(
    refs,
    alternativeCurrency
  );
  try {
    const response = await axios.post(getGolApiUrl(), golRequest);
    return createResponse(response);
  } catch (e) {
    Logger.error(e);
    return { success: false, errorMsg: "Něco se nezdařilo" };
  }
};

export const bookFlight = async (data): Promise<ResponseObject> => {
  const golRequest = await reservationRequestObject(data);
  try {
    const response = await axios.post(
      getGolApiUrl(),
      JSON.stringify(golRequest)
    );
    return createResponse(response);
  } catch (e) {
    Logger.error(e);
    return { success: false, errorMsg: "Něco se nezdařilo" };
  }
};

export const getParamConditions = async (refs) => {
  const golRequest = await getGetParamConditionsObject(refs);

  try {
    const response = await axios.post(getGolApiUrl(), golRequest);
    if (isFailed(response)) {
      Logger.error(
        response.data.GolApi.ResponseDetail.ParamConditionsError_1.Error
      );
      return [];
    }
    return response.data.GolApi.ResponseDetail.ParamConditionsResponse_1.FlightConditions.flatMap(
      ({ ConditionBlocks: { ConditionBlock } }) => {
        return ConditionBlock;
      }
    );
  } catch (e) {
    Logger.error(e);
    return false;
  }
};

export const loadAirportSuggestions = async (pattern: string) => {
  const golRequest = await getSearchDestinationRequestObject(pattern);
  const response = await axios.post(getGolApiUrl(), golRequest);

  if (isFailed(response)) {
    Logger.error(response.GolApi.ResponseDetail.SearchDestinationError_1.Error);
  } else {
    const airportSuggestions =
      response.data.GolApi.ResponseDetail.SearchDestinationsResponse_1
        .SearchedAirports.SearchedAirport;
    return airportSuggestions.map((oSearchedAirport) => {
      const oAirportCodeBook = response.data.GolApi.CodeBook.Airports.Airport.find(
        (oAirport) => oAirport.Code === oSearchedAirport.Destination
      );

      return {
        Code: oSearchedAirport.Destination,
        Parent: oSearchedAirport.Parent,
        Country: oAirportCodeBook.Country,
        State: oAirportCodeBook.State,
        $t: oAirportCodeBook.$t,
      };
    });
  }
};

function getGolApiUrlWithBackendId(golApiUrl) {
  const backendId = getBackendID();
  return golApiUrl + (backendId ? `?backend_id=${backendId}` : "");
}

export function getGolApiUrl() {
  const golApiUrlEnv =
    process.env.NEXT_PUBLIC_D4_golApiUrl ||
    (typeof window !== "undefined" && window?.__ENV?.NEXT_PUBLIC_D4_golApiUrl);
  const golApiUrl = golApiUrlEnv || sharedConfig.golApiUrl;

  return getGolApiUrlWithBackendId(golApiUrl);
}

import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import DayPicker from "react-day-picker";
import MomentLocaleUtils from "react-day-picker/moment";
import { connect } from "react-redux";

import { AppState } from "shared/data/reducers";
import { countriesWithUSFormat, monthYear } from "shared/lib/datetime";
import { rawDateToUrlDate, urlDateToRawDate } from "shared/lib/functions";

import Img from "@components/FileServer/Img";

interface Props {
  value: any;
  onChange: (string) => void;
  monthsToAdd: number;
  currentLanguage: string;
  disabledTillDate: string;
  disabledAfterDate?: string;
  defaultCountry: string;
}

function CalendarMonth(props: Props) {
  const [showMonth, setShowMonth] = useState(new Date().getMonth());
  const [showYear, setShowYear] = useState(new Date().getFullYear());

  // the toLocaleString API is not supporting some languages in Chrome, e.g. Albanian
  const monthYearCaption = (date: string | Date) =>
    props.currentLanguage === "al"
      ? moment(date).format("MMMM YYYY")
      : monthYear(date, props.currentLanguage);

  const constructDate = (month, year) => {
    return new Date(year, month, 1, 0, 0, 0);
  };

  const subtractMonths = (months = 1) => {
    const date = constructDate(showMonth, showYear);
    date.setMonth(date.getMonth() - months);
    const disabledTillDate = new Date(props.disabledTillDate);
    const shouldSubtractMonths =
      date > disabledTillDate ||
      (date.getFullYear() === disabledTillDate.getFullYear() &&
        date.getMonth() === disabledTillDate.getMonth());

    if (shouldSubtractMonths) {
      setShowMonth(date.getMonth());
      setShowYear(date.getFullYear());

      return constructDate(date.getMonth(), date.getFullYear());
    }

    return constructDate(showMonth, showYear);
  };

  const addMonths = (months, numberOfDisplayedMonths) => {
    const date = constructDate(showMonth, showYear);
    date.setMonth(date.getMonth() + months);
    const compareDate = new Date(date);
    compareDate.setMonth(compareDate.getMonth() + numberOfDisplayedMonths - 1);
    const shouldAddMonths =
      (!props.disabledAfterDate ||
        compareDate < new Date(props.disabledAfterDate)) &&
      compareDate < getNextYearDate();

    if (shouldAddMonths) {
      setShowMonth(date.getMonth());
      setShowYear(date.getFullYear());
      return constructDate(date.getMonth(), date.getFullYear());
    }

    return constructDate(showMonth, showYear);
  };

  useEffect(() => {
    const configureInit = () => {
      const monthsToAdd = props.monthsToAdd ? props.monthsToAdd : 0;
      setShowMonth(
        props.value
          ? urlDateToRawDate(props.value).getMonth() + monthsToAdd
          : showMonth + monthsToAdd
      );
      setShowYear(
        props.value ? urlDateToRawDate(props.value).getFullYear() : showYear
      );
    };
    configureInit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDayClick = (date) => {
    props.onChange(rawDateToUrlDate(date));
  };

  const getNextYearDate = () => {
    const today = new Date();
    const DAYS_IN_YEAR = 365;
    today.setDate(today.getDate() + DAYS_IN_YEAR);
    return today;
  };

  const { value, defaultCountry } = props;
  const today = new Date();
  const disabledDaysAfter = props.disabledAfterDate
    ? {
        after: props.disabledAfterDate,
      }
    : {};

  const firstDayOfWeek = countriesWithUSFormat.includes(
    defaultCountry.toLowerCase()
  )
    ? 0
    : 1;

  const aRef = useRef();
  const bRef = useRef();

  return (
    <div>
      <div className="header-search-form-calendar-month" />

      <DayPicker
        className="desktop"
        localeUtils={MomentLocaleUtils}
        locale={props.currentLanguage}
        numberOfMonths={2}
        pagedNavigation
        firstDayOfWeek={firstDayOfWeek}
        ref={aRef}
        navbarElement={() => (
          <div>
            <div
              role="button"
              className="header-search-form-calendar-month-title-link previous pointer"
              onClick={() => {
                const newMonth = subtractMonths();
                aRef.current.showMonth(newMonth);
              }}
            >
              <Img
                src="/static/images/ico-arrow-down.svg"
                className="header-search-form-calendar-month-title-link-previous"
                alt="down arrow icon"
              />
            </div>

            <div
              role="button"
              className="header-search-form-calendar-month-title-link next pointer"
              onClick={() => {
                const newMonth = addMonths(1, 2);
                aRef.current.showMonth(newMonth);
              }}
            >
              <Img
                src="/static/images/ico-arrow-down.svg"
                className="header-search-form-calendar-month-title-link-next"
                alt="down arrow icon"
              />
            </div>
          </div>
        )}
        captionElement={(e) => (
          <div className="DayPicker-Caption header-search-form-calendar-month-title-text">
            {monthYearCaption(e.date)}
          </div>
        )}
        initialMonth={urlDateToRawDate(value)}
        toMonth={getNextYearDate()}
        onDayClick={handleDayClick}
        selectedDays={urlDateToRawDate(value)}
        disabledDays={[
          {
            before: props.disabledTillDate ? props.disabledTillDate : today,
          },
          disabledDaysAfter,
        ]}
      />

      <DayPicker
        className="mobile"
        localeUtils={MomentLocaleUtils}
        locale={props.currentLanguage}
        numberOfMonths={1}
        pagedNavigation
        firstDayOfWeek={firstDayOfWeek}
        ref={bRef}
        navbarElement={() => (
          <div>
            <div
              role="button"
              className="header-search-form-calendar-month-title-link previous pointer"
              onClick={() => bRef.current.showMonth(subtractMonths())}
            >
              <Img
                src="/static/images/ico-arrow-down.svg"
                className="header-search-form-calendar-month-title-link-previous"
              />
            </div>

            <div
              role="button"
              className="header-search-form-calendar-month-title-link next pointer"
              onClick={() => bRef.current.showMonth(addMonths(1, 1))}
            >
              <Img
                src="/static/images/ico-arrow-down.svg"
                className="header-search-form-calendar-month-title-link-next"
              />
            </div>
          </div>
        )}
        captionElement={(e) => (
          <div className="DayPicker-Caption header-search-form-calendar-month-title-text">
            {monthYearCaption(e.date)}
          </div>
        )}
        initialMonth={urlDateToRawDate(value)}
        toMonth={getNextYearDate()}
        onDayClick={handleDayClick}
        selectedDays={urlDateToRawDate(value)}
        disabledDays={{
          before: props.disabledTillDate ? props.disabledTillDate : today,
        }}
      />
    </div>
  );
}

export default connect((state: AppState) => ({
  currentLanguage: state.requestorConfig.currentLanguage,
  defaultCountry:
    state.storage.frontendSettings?.dealerFrontendSettings?.defaultCountry ??
    "",
}))(CalendarMonth);

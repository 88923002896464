import React from "react";
import { useSelector } from "react-redux";

import Link from "@components/UI/Link";

import { AppState } from "@shared/data/reducers";

export default function HeaderMenu() {
  const menuItems = useHeaderMenu();
  const { anonymousSearchEnabled, isLoggedIn } = useSelector(
    (state: AppState) => ({
      isLoggedIn: state.user.isLoggedIn,
      anonymousSearchEnabled:
        state.storage.frontendSettings.dealerCorporateSettings
          ?.enableAnonymousSearch !== "false",
    })
  );
  const HEADER_MENU_LINE_EMPTY = <div className="header-menu-line-empty" />;

  if (isLoggedIn || anonymousSearchEnabled) {
    return (
      <div className="container">
        {menuItems.length > 0 ? (
          <div className="header-menu-line">
            {menuItems.map((menuItem) => {
              if (menuItem.alternativeUrl) {
                return (
                  <a
                    key={menuItem.label}
                    href={menuItem.alternativeUrl}
                    target="_blank"
                  >
                    {menuItem.label}
                  </a>
                );
              }
              return (
                <Link
                  href={{
                    pathname: "/page",
                    query: { url: menuItem.url },
                  }}
                  as={`/page/${menuItem.url}`}
                  key={menuItem.url}
                >
                  <a>{menuItem.label}</a>
                </Link>
              );
            })}
          </div>
        ) : (
          HEADER_MENU_LINE_EMPTY
        )}
      </div>
    );
  }
  // Data can be sensitive
  return HEADER_MENU_LINE_EMPTY;
}

export function useHeaderMenu() {
  const { menuItems } = useSelector((state: AppState) => ({
    menuItems: state.requestorConfig.menuItems,
  }));

  return menuItems;
}

const sharedConfig = require("../config/sharedConfig");

const { requestorClientId, requestorPassword } = sharedConfig;

const DEFAULT_FILESERVER_DOMAIN_URL = requestorClientId;

function getRequestor(hostname) {
  return {
    ClientId: getClientId(hostname),
    Password: process.env.NEXT_PUBLIC_D4_requestorPassword || requestorPassword,
  };
}

function getClientId(hostname) {
  const clientIdEnv = process.env.NEXT_PUBLIC_D4_requestorClientId;
  const resolvedClientId = clientIdEnv || requestorClientId;

  if (resolvedClientId && resolvedClientId.length > 0) {
    return resolvedClientId;
  }

  return getClientIdFromHostname(hostname);
}

function getClientIdFromHostname(hostname) {
  return hostname.split(":")[0];
}

function getDomainForFileServer(domain) {
  if (domain.includes("localhost") || domain.includes("d4:")) {
    return (
      process.env.NEXT_PUBLIC_D4_requestorClientId ||
      DEFAULT_FILESERVER_DOMAIN_URL
    );
  }
  // removes port from url on gol.
  if (domain.includes(".in.") || domain.startsWith("gitlab")) {
    return process.env.NEXT_PUBLIC_D4_requestorClientId || domain.split(":")[0];
  }

  return process.env.NEXT_PUBLIC_D4_requestorClientId || domain;
}

module.exports = {
  getClientId,
  getRequestor,
  getDomainForFileServer,
  DEFAULT_FILESERVER_DOMAIN_URL,
};

import axiosRequest from "axios";

import axios from "../../lib/GolAxios";
import { getResponseError, isFailed, onlyUnique } from "../../lib/functions";
import { buildGolRequestForDetailRequestor } from "../../lib/golCopy";
import {
  getCountriesRequestObject,
  getLoyaltyProgramsRequestObject,
  getTransportCompaniesRequestObject,
} from "../../lib/golRequestObjects";
import { getInitialClientId } from "../../lib/requestorFunctions";
import * as actionTypes from "./actionTypes";
import { setRequestorConfig } from "./requestorConfig";

const sharedConfig = require("../../config/sharedConfig");

export const setDefaultAirportSuggestions = (data) => {
  return {
    type: actionTypes.SET_DEFAULT_AIRPORT_SUGGESTIONS,
    data,
  };
};

export const initClientDataFail = (error) => {
  return {
    type: actionTypes.ERROR_INIT_CLIENT,
    error,
  };
};

export const loadWebRequestorDetails = ({
  golApiUrl,
  selectedLanguage,
  requestorPublicKey,
  forcedCustomerToken,
  forcedClientId,
  redirect404,
}: {
  golApiUrl: string;
  selectedLanguage: string | string[];
  requestorPublicKey: string;
  forcedCustomerToken?: string;
  forcedClientId?: string;
  redirect404?: () => void;
}) => {
  return async (dispatch) => {
    const response = await axiosRequest.post(
      golApiUrl,
      await buildGolRequestForDetailRequestor({
        forcedRequestorPublicKey: requestorPublicKey,
        forcedCustomerToken,
        forcedClientId,
      })
    );

    const apiUserNotFound =
      response.data.GolApi?.ResponseDetail?.SystemRequestError_1?.Error?.[0]
        ?.Code === "1001" || false;

    const isDisabledDealer =
      response.data.GolApi?.ResponseDetail?.DetailRequestorError_1?.Error?.[0]
        ?.Code === "2009" || false;

    if ((apiUserNotFound || isDisabledDealer) && redirect404) {
      return redirect404();
    }

    if (isFailed(response)) {
      await dispatch(initClientDataFail(getResponseError(response)));
    } else {
      await dispatch(
        setRequestorData({
          country: response.data.GolApi.Settings.Localization.Country,
        })
      );

      await dispatch(
        setRequestorConfig(
          "currency",
          response.data.GolApi.ResponseDetail.DetailRequestorResponse_1
            .AgencyRegionSettings.Currency.Code
        )
      );

      await dispatch(
        setRequestorConfig(
          "module",
          response.data.GolApi.ResponseDetail.DetailRequestorResponse_1.RequestorSettings?.EnabledModules?.Module?.map(
            (module) => module.$t
          )
        )
      );

      const requestorSettings =
        response.data.GolApi.ResponseDetail.DetailRequestorResponse_1
          .RequestorSettings;

      await dispatch(
        setRequestorConfig(
          "passwordCustomerLoginAllowed",
          requestorSettings?.OtherSettings?.PasswordCustomerLogin?.Allowed ===
            "true"
        )
      );

      await dispatch(
        setRequestorConfig(
          "ssoIdentityProvider",
          requestorSettings?.OtherSettings?.SsoIdentityProviders
            ?.SsoIdentityProvider
        )
      );

      await dispatch(
        setRequestorConfig(
          "waitPageImageUrl",
          requestorSettings?.WaitPageSettings?.ImageUrl?.$t
        )
      );

      const supportedLanguage =
        requestorSettings.SupportedLanguages.SupportedLanguage;

      const alternativeCurrencies =
        requestorSettings.AlternativeCurrencies.AlternativeCurrency;

      const hotelsEnabled = !!requestorSettings.EnabledModules.Module.find(
        (enabledModule) => enabledModule.$t === "Hotel"
      );

      if (hotelsEnabled) {
        await dispatch(setRequestorConfig("hotelsEnabled", true));
      }

      if (requestorSettings?.OtherSettings?.PromoCodes?.Allowed === "true") {
        await dispatch(setRequestorConfig("promoCodesEnabled", true));
      }

      const currentLanguage = selectedLanguage || supportedLanguage[0].Language;
      await dispatch(setRequestorConfig("currentLanguage", currentLanguage));

      const allowedTravelerTypes = supportedLanguage
        .find((language) => language.Language === currentLanguage)
        .TravelerTypes.TravelerType.sort((a, b) => {
          if (a.Code === "ADT" && b.Code !== "ADT") {
            return 1;
          }
          return 0;
        });

      const allowedTravelerTypesSorted = allowedTravelerTypes.find(
        (allowedTravelerType) => allowedTravelerType.Code === "ADT"
      )
        ? [
            allowedTravelerTypes.find(
              (allowedTravelerType) => allowedTravelerType.Code === "ADT"
            ),
          ].concat(
            allowedTravelerTypes.filter(
              (allowedTravelerType) => allowedTravelerType.Code !== "ADT"
            )
          )
        : allowedTravelerTypes;
      await dispatch(
        setRequestorConfig("allowedTravelerTypes", allowedTravelerTypesSorted)
      );

      await dispatch(
        setRequestorConfig(
          "menuItems",
          getMenuItems(supportedLanguage, currentLanguage)
        )
      );

      await dispatch(
        setRequestorConfig("supportedLanguages", supportedLanguage)
      );

      await dispatch(
        setRequestorConfig(
          "alternativeCurrencies",
          alternativeCurrencies.map(({ Code }) => Code)
        )
      );

      await dispatch(
        setRequestorConfig(
          "specialOfferEnabled",
          requestorSettings?.SpecialOfferSettings?.SpecialOfferStatus
            ?.Active === "true"
        )
      );

      const passengerPrefix = parsePassengerPrefixes(
        requestorSettings.OtherSettings.AllowedPassengerPrefixes
          .AllowedPassengerPrefix
      );
      if (requestorSettings.FrontendSettings !== undefined) {
        const frontendSettings = JSON.parse(
          requestorSettings.FrontendSettings.$t
        );
        if (frontendSettings) {
          await dispatch(setFrontEndSettings(frontendSettings));
        }
      }

      await dispatch(setPassengerPrefix(passengerPrefix));
      const defaultAirports = getDefaultAirports(
        requestorSettings.FlightSearchSettings.DepartureAirports
          .DepartureAirport,
        response.data.GolApi.CodeBook.Airports.Airport
      );
      await dispatch(setDefaultAirportSuggestions(defaultAirports));
      await dispatch(
        setCalendarSettings({
          departureDays:
            requestorSettings.FlightSearchSettings.Journey.DepartureDate.Days,
          durationOfStay:
            requestorSettings.FlightSearchSettings.Journey.DurationOfStay.Days,
        })
      );
    }
  };
};

export const parsePassengerPrefixes = (allowedPrefixes) => {
  const reducer = (accumulator, currentValue) => {
    const currArr = currentValue.Prefix.map((prefix) => prefix.NamePrefix);
    return [...accumulator, ...currArr];
  };
  const ar = allowedPrefixes.reduce(reducer, []);
  const uniqeValues = ar.filter(onlyUnique);
  return uniqeValues.map((el) => {
    const optObj = { label: el, value: el, type: [] };
    allowedPrefixes.forEach(({ PassengerType, Prefix }) => {
      Prefix.forEach(({ NamePrefix }) => {
        if (NamePrefix === el) {
          optObj.type.push(PassengerType);
        }
      });
    });
    return optObj;
  });
};

export const setCalendarSettings = (data) => {
  return {
    type: actionTypes.SET_CALENDAR_SETTINGS,
    data,
  };
};

export const setPassengerPrefix = (data) => {
  return {
    type: actionTypes.SET_PASSENGER_PREFIXES,
    data,
  };
};

export const setRequestorData = (data) => {
  // mock so far
  return {
    type: actionTypes.SET_REQUESTOR_DATA,
    data,
  };
};

export const loadDefaultAirportsForMobile = (defaultAirportsOptions) => {
  return async (dispatch) => {
    if (defaultAirportsOptions && defaultAirportsOptions.length > 0) {
      // only mobile
      await dispatch(setSearch("from", defaultAirportsOptions[0].Code));
      await dispatch(
        setSearch("searchFormAirports", {
          [defaultAirportsOptions[0].Code]: defaultAirportsOptions[0].label,
        })
      );
    }
  };
};

export const getDefaultAirports = (
  slimAirports: { Code: string }[],
  fatAirports: {
    Code: string;
    Country: string;
    State: string;
    $t: string;
  }[] = []
) => {
  return slimAirports
    .filter((airport) => airport.Code !== "")
    .map((airport) => {
      const airportObj = {
        label: "",
        Code: "",
        Country: "",
        Parent: "",
      };
      if (airport.Code.includes("/")) {
        const airportCodes = airport.Code.split("/");
        let combinedLabel = "";
        airportCodes.forEach((code) => {
          const res = fatAirports.find(
            (airportDest) => airportDest.Code === code
          );
          airportObj.Country = res.Country;
          combinedLabel += ` / ${res.$t}`;
        });
        airportObj.label = combinedLabel.slice(3);
      } else {
        const res = fatAirports.find(
          (airportDest) => airportDest.Code === airport.Code
        );
        if (res !== undefined) {
          airportObj.Country = res.Country;
          airportObj.label = res.$t;
        }
      }
      airportObj.Code = airport.Code;
      return airportObj;
    });
};

export const setCountries = (data) => {
  return {
    type: actionTypes.SET_COUNTRIES,
    data,
  };
};

export const setAirlines = (data) => {
  return {
    type: actionTypes.SET_AIRLINES,
    data,
  };
};

export const setIsMobileInitDone = () => {
  return {
    type: actionTypes.SET_IS_MOBILE_INNIT_DONE,
  };
};

export const loadCountries = (req, dispatch) => {
  return async () => {
    const clientId = getInitialClientId(req.hostname);

    const requestData = {
      customerToken: req?.cookies?.d4customerToken,
      requestorPublicKey: req?.cookies?.d4requestorPublicKey,
      clientId,
    };

    const response = await axiosRequest.post(
      getGOLAPIUrl(req),
      await getCountriesRequestObject(requestData)
    );

    if (isFailed(response)) {
      await dispatch(initClientDataFail(getResponseError(response)));
      return;
    }

    const countries =
      response.data.GolApi.ResponseDetail.ExportCountryResponse_1.Country;

    const formattedCountries = countries.map((country) => ({
      label: country.$t,
      value: country.Code,
      state: country.State,
    }));

    const countryLookup = formattedCountries.reduce((acc, country) => {
      const key = `${country.value}${country.state ? `_${country.state}` : ""}`;
      acc[key] = country.label;
      return acc;
    }, {});

    return countryLookup;
  };
};

export const loadAirlines = (req, restrictAirlines, dispatch) => {
  return async () => {
    const clientId = getInitialClientId(req.hostname);

    const requestData = {
      customerToken: req?.cookies?.d4customerToken,
      requestorPublicKey: req?.cookies?.d4requestorPublicKey,
      clientId,
    };

    const response = await axiosRequest.post(
      getGOLAPIUrl(req),
      await getTransportCompaniesRequestObject(requestData)
    );

    if (isFailed(response)) {
      await dispatch(initClientDataFail(getResponseError(response)));
      return;
    }

    const transportCompanies =
      response.data.GolApi.ResponseDetail.ExportTransportCompanyResponse_1
        .TransportCompany;

    const companies = transportCompanies
      .filter((company) => company.Type === "company")
      .map((company) => ({
        value: company.Code,
        label: company.Name.$t,
      }));

    const alliances = transportCompanies
      .filter((alliance) => alliance.Type === "alliance")
      .map((alliance) => ({
        value: alliance.Code,
        label: alliance.Name.$t,
      }));

    alliances.push({ value: "", label: "", isDisabled: true });

    const airlines = [...alliances, ...companies];

    if (restrictAirlines) {
      return airlines.filter((airline) =>
        restrictAirlines.includes(airline.value)
      );
    }

    return airlines;
  };
};

export const setSearchQuery = (query) => {
  return { type: actionTypes.SET_QUERY, data: query };
};

export function getMenuItems(supportedLanguages, language) {
  const languageObj = supportedLanguages.find(
    (supportedLanguage) => supportedLanguage.Language === language
  );

  if (!languageObj || languageObj.MenuItems.MenuItem.length === 0) {
    return [];
  }

  return languageObj.MenuItems.MenuItem.map((menuItem) => ({
    label: menuItem.Label.$t,
    url: menuItem.TextType,
    ...(menuItem.AlternativeUrl.$t
      ? { alternativeUrl: menuItem.AlternativeUrl.$t }
      : {}),
  }));
}

export const setFrontEndSettings = (data) => {
  return {
    type: actionTypes.SET_FRONTEND_SETTINGS,
    data,
  };
};

export const setSearch = (name, value) => {
  // duplicate from search.ts, to remove yellow warning /rn bug/ later give back
  return { type: actionTypes.SEARCH_SET, data: { name, value } };
};

export const getLoyaltyPrograms = () => {
  return async (dispatch) => {
    const response = await axios.post(
      "",
      JSON.stringify(await getLoyaltyProgramsRequestObject())
    );
    if (isFailed(response)) {
      // TODO: we are setting loyalty programs with error response
      await dispatch(setLoyaltyPrograms(response));
    } else {
      const programs = response.data.GolApi.ResponseDetail.ExportLoyaltyProgramResponse_1.Program.map(
        (program) => ({
          value: program.Code,
          label: program.$t,
        })
      );
      await dispatch(setLoyaltyPrograms(programs));
    }
  };
};

const setLoyaltyPrograms = (data) => {
  return { type: actionTypes.SET_LOYALTY_PROGRAMS, data };
};

export function getGOLAPIUrl(req) {
  const backendId = req?.cookies?.backend_id
    ? `?backend_id=${req.cookies.backend_id}`
    : "";

  const isHtmlPackage = req.headers && Object.keys(req.headers).length === 0;
  const isLocalhost = req.headers.host?.includes("localhost:");

  const baseUrl =
    sharedConfig.golApiUrlInternal && (isLocalhost || isHtmlPackage)
      ? sharedConfig.golApiUrlInternal
      : sharedConfig.golApiUrl;
  return `${process.env.NEXT_PUBLIC_D4_golApiUrl || baseUrl}${backendId}`;
}

import classnames from "classnames";
import { SingletonRouter, withRouter } from "next/router";
import React from "react";
import { IntlShape, defineMessages } from "react-intl";
import { connect } from "react-redux";
import { components } from "react-select";

import { AppState } from "shared/data/reducers";
import { formattedMessageParams } from "shared/messages";

import Checkbox from "@components/UI/Checkbox";
import FormattedMessage from "@components/UI/FormattedMessage";
import SimpleSelect from "@components/UI/SimpleSelect";

import { isIframe } from "@lib/iframe";

const EXPORT_HTML_PACKAGE = Boolean(process.env.NEXT_PUBLIC_EXPORT_PACKAGE);

const { addBreakfast, onlyDirect, allAirlines } = defineMessages({
  addBreakfast: formattedMessageParams("FilterBar.hotels.addBreakfast"),
  onlyDirect: formattedMessageParams("FilterBar.onlyDirect"),
  allAirlines: formattedMessageParams("FilterBar.allAirlines"),
});

interface Props {
  intl: IntlShape;
  selectAirlines: (string) => void;
  search: () => void;
  increaseTolerance: () => void;
  decreaseTolerance: () => void;
  onDirectFlightChange: () => void;
  variableDays: boolean;
  selectedTransportCompany: string;
  directFlights: boolean;
  airlines: [{ value: string; label: string }];
  hotelsEnabled: boolean;
  router: SingletonRouter;
  carrierFilter: string[] | null;
}

function FilterBarMobile(props: Props) {
  const { intl, hotelsEnabled } = props;

  const options = [
    {
      value: "all",
      label: intl.formatMessage(allAirlines),
    },
    ...(props.airlines || []),
  ];

  const isHotelSearchForm =
    props.router.query &&
    props.router.query.typeSearch &&
    props.router.query.typeSearch === "hotels";

  return (
    <div
      className={classnames("header-search-form-additional", {
        iframe: isIframe(props.router.route),
      })}
    >
      <div
        className={`header-search-form-additional-top ${classnames({
          hidden: isHotelSearchForm,
        })}`}
      >
        <div className="header-search-form-additional-top-first header-search-form-additional-mobile-one">
          {isHotelSearchForm ? (
            <Checkbox
              id="header-search-form-only-direct-mobile"
              checked={props.directFlights}
              label={intl.formatMessage(addBreakfast)}
              onClick={props.onDirectFlightChange}
            />
          ) : (
            <Checkbox
              id="header-search-form-only-direct-mobile"
              checked={props.directFlights}
              label={intl.formatMessage(onlyDirect)}
              onClick={props.onDirectFlightChange}
              idLabel="GOL_package-textStorage-FilterBar.onlyDirect-mobile"
            />
          )}
        </div>
        {!isHotelSearchForm && (
          <div
            className="header-search-form-additional-top-second"
            style={{ top: -3, position: "relative" }}
          >
            <SimpleSelect
              hideDropdown
              testId="GOL_package-search-airlines-mobile"
              clearable={false}
              options={[...options]}
              defaultValue={options[0]}
              value={options.find(
                (airline) => airline.value === props.selectedTransportCompany
              )}
              styles={{
                valueContainer: (base) => ({
                  ...base,
                  justifyContent: "center",
                  paddingLeft: "11px",
                  display: "flex",
                  fontSize: 14,
                }),
              }}
              components={{
                SingleValue(svprops) {
                  return (
                    <components.SingleValue
                      {...svprops}
                      className={classnames("search-airlines", {
                        "search-airlines--filled": props.airlinesTouched,
                      })}
                    />
                  );
                },
              }}
              onChange={props.selectAirlines}
              displayOnlyAfterMount
              iframeView
            />
          </div>
        )}
        <div className="clearfix" />
      </div>
      {(props.variableDays || EXPORT_HTML_PACKAGE) && (
        <>
          <div
            id="GOL_package-variableDays-mobile"
            className="header-search-form-additional-tolerance"
          >
            <span>Tolerance:</span>
            <div className="header-search-form-additional-bottom-tolerance">
              <div
                role="button"
                id="GOL_package-variableDays-minus-mobile"
                className="header-search-form-additional-bottom-tolerance-minus"
                onClick={props.decreaseTolerance}
              >
                -
              </div>
              <div
                role="button"
                id="GOL_package-variableDays-plus-mobile"
                className="header-search-form-additional-bottom-tolerance-plus"
                onClick={props.increaseTolerance}
              >
                +
              </div>
              {EXPORT_HTML_PACKAGE ? (
                <span id="GOL_package-variableDays-value-mobile" />
              ) : (
                <FormattedMessage
                  id="FilterBar.days"
                  values={{ days: props.toleranceDays }}
                />
              )}
            </div>
          </div>
          {/* <div className="header-search-form-additional-bottom-weekends"> */}
          {/*  <Checkbox */}
          {/*    checked={props.weekends} */}
          {/*    label="Prodloužené víkendy" */}
          {/*    onClick={props.extendedWeekend} */}
          {/*  /> */}
          {/*  <Tooltip */}
          {/*    placement="top" */}
          {/*    mouseEnterDelay={0} */}
          {/*    mouseLeaveDelay={0.1} */}
          {/*    trigger={["hover", "click"]} */}
          {/*    overlay={ */}
          {/*      <div className="information-tooltip-content"> */}
          {/*        <b>Tolerance:</b> */}
          {/*        <div> */}
          {/*          Prohledávání okolních dní. Hodnota je počet dnů na obě */}
          {/*          strany od zvolených dat. */}
          {/*        </div> */}
          {/*        /!*<br />*!/ */}
          {/*        /!*<b>Prodloužené víkendy:</b>{" "}*!/ */}
          {/*        /!*<div>*!/ */}
          {/*        /!*  Nabídky na měsíc dopředu od zvoleného data. Vráceny budou*!/ */}
          {/*        /!*  nejlepší nabídky s pobytem přes víkend.*!/ */}
          {/*        /!*</div>*!/ */}
          {/*      </div> */}
          {/*    } */}
          {/*  > */}
          {/*    <div className="information-tooltip-circle">i</div> */}
          {/*  </Tooltip> */}
          {/* </div> */}
          <div className="clearfix" />{" "}
        </>
      )}
      <div className="header-search-form-additional-desktop-one-search-button no-border">
        <button
          type="button"
          className="button-main-search button-secondary-color button-main-search-mobile"
          onClick={props.search}
          id="GOL_package-textStorage-SearchForm.searchFlights-mobile"
        >
          {isHotelSearchForm ? (
            <FormattedMessage id="SearchForm.hotels.search" />
          ) : hotelsEnabled ? (
            <FormattedMessage id="SearchForm.searchFlights" />
          ) : (
            <FormattedMessage id="SearchForm.search" />
          )}
        </button>
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => ({
  airlines: state.storage.airlines,
  hotelsEnabled: state.requestorConfig.hotelsEnabled,
});

export default withRouter(connect(mapStateToProps)(FilterBarMobile));

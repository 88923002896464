import classnames from "classnames";
import React, { forwardRef, useEffect, useState } from "react";
import { IntlShape, injectIntl } from "react-intl";
import Select, { components } from "react-select";

import Img from "@components/FileServer/Img";
import FormattedMessage from "@components/UI/FormattedMessage";

export function FormControl(props) {
  const { value, error, touched } = props.selectProps.controlOptions || {};
  const { disabled } = props.selectProps;

  return (
    <components.Control
      {...props}
      className={classnames("custom-select-control", {
        "custom-select-control--filled": value,
        "custom-select-control--error": error,
        "custom-select-control--error-and-touched": error && touched,
        "custom-select-control--disabled": disabled,
      })}
    />
  );
}

interface BaseSelectProps {
  isClearable?: boolean;
  center?: boolean;
  right?: boolean;
  hideDropdown?: boolean;
  displayOnlyAfterMount?: boolean;
  testId?: string;
  instanceId?: string;
  control?: any;
  menu?: any;
  styles?: any;
  value: any;
  options: { label: string; value: string }[];
  defaultValue?: string;
  isSearchable?: boolean;
  clearable?: boolean;
  clearIndicator?: React.ReactElement;
  components?: any;
  onChange: any;
  className?: string;
  classNamePrefix?: string;
  autoComplete?: string;
  iframeView?: boolean;
  placeholder?: any;
  disabled?: boolean;
  customClass?: string;
  controlOptions?: { value: any; error: any; touched?: boolean };
}

const CustomInput = forwardRef(
  (
    inputProps: {
      selectProps: {
        testId: string;
        autoComplete: boolean;
      };
    },
    ref
  ) => (
    <components.Input
      {...inputProps}
      ref={ref}
      data-cy={inputProps.selectProps.testId}
      style={{ flexGrow: 1 }}
      autoComplete={inputProps.selectProps.autoComplete}
    />
  )
);

interface SimpleSelectProps extends BaseSelectProps {
  intl: IntlShape;
}

const SimpleSelect = (props: SimpleSelectProps) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    setShow(true);
  }, []);

  const DropdownIndicator = (indicatorProps) => {
    if (!props.hideDropdown) {
      return (
        <components.DropdownIndicator {...indicatorProps}>
          <Img src="/static/images/ico-arrow-down.svg" />
        </components.DropdownIndicator>
      );
    }
    return null;
  };

  const SingleValue = (valueProps) => {
    return (
      <div id={`${props.testId}-singleValue`}>
        <components.SingleValue {...valueProps} />
      </div>
    );
  };

  const Option = (optionProps) => (
    <div data-testid={props.testId}>
      <components.Option
        className={classnames("simple-select-option", {
          "simple-select-option--selected":
            !optionProps.isFocused && optionProps.isSelected,
          "simple-select-option--focused": optionProps.isFocused,
        })}
        {...optionProps}
      />
    </div>
  );

  const Menu = (menuProps) => (
    <components.Menu
      {...menuProps}
      className={classnames("select-menu-outer", {
        "filter-bar-airlines-iframe": props.iframeView,
      })}
    />
  );

  const NoOptionsMessage = (msgProps) => (
    <components.NoOptionsMessage {...msgProps}>
      <div>
        <FormattedMessage id="SimpleSelect.noOptions" />
      </div>
    </components.NoOptionsMessage>
  );

  const { defaultValue, center, right } = props;

  let justifyValueContainerContent = "flex-start";
  if (center) justifyValueContainerContent = "center";
  if (right) justifyValueContainerContent = "flex-end";

  return (
    <Select
      data-testid={props.testId}
      id={props.testId}
      instanceId={props.testId}
      placeholder={
        defaultValue && typeof defaultValue !== "object"
          ? defaultValue
          : props.placeholder
          ? props.placeholder
          : props.intl.formatMessage({ id: "Select.placeholder" })
      }
      isClearable={props.isClearable}
      isDisabled={props.disabled}
      className={classnames({
        "simple-select-beforemount": props.displayOnlyAfterMount,
        "simple-select-aftermount-appear": props.displayOnlyAfterMount && show,
        [props.testId]: props.testId,
        [props.customClass]: props.customClass,
      })}
      defaultValue={
        defaultValue
          ? props.options.find((opt) => opt.value === defaultValue)
          : undefined
      }
      menuPortalTarget={
        typeof window !== "undefined"
          ? window.document.querySelector("body")
          : null
      }
      {...props}
      components={{
        DropdownIndicator: !props.hideDropdown ? DropdownIndicator : null,
        IndicatorSeparator: () => null,
        Option,
        Menu,
        SingleValue,
        NoOptionsMessage,
        Input: CustomInput,
        ...props.components,
      }}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (base) =>
          props.control
            ? props.control
            : {
                ...base,
                backgroundColor: "transparent",
                boxShadow: "none",
                border: "none",
                flexGrow: 1,
                textAlign: "left",
              },
        dropdownIndicator: (base, state) => ({
          ...base,
          transition: "all .2s ease",
          cursor: "pointer",
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        }),
        valueContainer: (base) => ({
          ...base,
          justifyContent: justifyValueContainerContent,
          paddingLeft: "11px",
          display: "flex",
          fontSize: 13,
          cursor: "pointer",
        }),
        placeholder: (base) => ({
          ...base,
          justifyContent: "left",
        }),
        menu: (base) =>
          props.menu
            ? props.menu
            : {
                ...base,
                transition: "opacity 150ms ease-in-out",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                boxShadow: "0 0 13px 0 rgba(158, 160, 172, 0.1)",
                marginTop: 0,
                borderRadius: 0,
                zIndex: 99999,
              },
        menuList: (base) => ({
          ...base,
          paddingTop: 0,
        }),
        input: (base) => ({ ...base }),
        ...props.styles,
      }}
      onChange={props.onChange}
    />
  );
};

export default injectIntl(SimpleSelect);

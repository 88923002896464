import config from "shared/config.json";

import requestorIdEnv from "./requestorIdEnv";

export const getEnvironmentFromRequestorClientId = () => {
  const requestorClientId =
    process.env.NEXT_PUBLIC_D4_requestorClientId || config.requestorClientId;

  return requestorIdEnv(requestorClientId);
};

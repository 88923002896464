const urlPackage = require("url");
const axios = require("axios");
const config = require("shared/config.json");
const Logger = require("shared/services/Logger.ts");
const { getRequestor } = require("shared/services/requestorConfiguration.ts");

const COOKIE_CUSTOMER_TOKEN_NAME = "d4customerToken";
const COOKIE_CUSTOMER_NAME_NAME = "d4customerName";
const COOKIE_REQUESTOR_PUBLIC_KEY = "d4requestorPublicKey";

function isUnloggedPage({ urlPage }) {
  return !(
    urlPage.includes("/static/") ||
    urlPage.includes("/_next/") ||
    urlPage.includes("/__ENV.js") ||
    urlPage.includes("/create-new-password") ||
    urlPage.includes("/register") ||
    urlPage.includes("/booked") ||
    urlPage.includes("/iframe") ||
    urlPage.includes("/lowCostSearch") ||
    urlPage.includes("/redirectToBooked") ||
    (urlPage.includes("/login") && urlPage.includes("isLoginRequired=true")) ||
    (urlPage.includes("/login") && urlPage.includes("saml="))
  );
}

function isUnAllowedRegisterPage({ registrationEnabled, urlPage }) {
  return urlPage.includes("/register") && !registrationEnabled;
}

function isProtectedPage(urlPage) {
  return urlPage.includes("/profile") || urlPage.includes("/reservation");
}

async function isLogged(req, res) {
  if (
    !req.cookies[COOKIE_CUSTOMER_TOKEN_NAME] ||
    !req.cookies[COOKIE_CUSTOMER_NAME_NAME]
  ) {
    return false;
  }

  return isCustomerTokenValid(req, res);
}

async function isCustomerTokenValid(req, res) {
  const customerToken = req.cookies[COOKIE_CUSTOMER_TOKEN_NAME];
  const requestorPublicKey = req.cookies[COOKIE_REQUESTOR_PUBLIC_KEY];
  const Authorization = requestorPublicKey
    ? { RequestorPublicKey: { $t: requestorPublicKey } }
    : { Requestor: getRequestor(req.hostname) };
  Authorization.UserToken = { $t: customerToken };

  const reqObj = {
    GolApi: {
      PassiveSessionId: getPassiveSessionId({ cookies: req.cookies, res }),
      Authorization: { ...Authorization },
      Settings: { Localization: { Language: "cs", Country: "CZ" } },
      RequestDetail: {
        DetailCustomerRequest_1: {},
      },
    },
  };

  const isLocalhost = req.headers.host?.includes("localhost:");

  const baseUrl =
    isLocalhost && config.golApiUrlInternal
      ? config.golApiUrlInternal
      : config.golApiUrl;

  const response = await axios.post(
    process.env.NEXT_PUBLIC_D4_golApiUrl || baseUrl,
    JSON.stringify(reqObj)
  );

  if (response.data.GolApi.ResponseDetail.DetailCustomerError_1) {
    // invalid customerToken, either too old or attack
    Logger.warn(
      `Invalid customerToken (${req.hostname}):`,
      JSON.stringify(response.data.GolApi.ResponseDetail.DetailCustomerError_1)
    );
    return false;
  }

  return true;
}

async function ensureValidCustomerToken(req, res) {
  if (
    !req.cookies[COOKIE_CUSTOMER_TOKEN_NAME] ||
    !req.cookies[COOKIE_CUSTOMER_NAME_NAME]
  ) {
    return true;
  }

  // delete cookies when they are invalid
  if (!(await isCustomerTokenValid(req, res))) {
    Logger.warn(
      "Invalid tokens, clearing. Maybe you changed config.json and didn't restart app?"
    );

    res.clearCookie(COOKIE_CUSTOMER_TOKEN_NAME);
    res.clearCookie(COOKIE_CUSTOMER_NAME_NAME);

    res.writeHead(302, {
      Location: "/",
    });

    return res.end();
  }

  return true;
}

function getFullUrlFunction(req, addPathname = true) {
  return urlPackage.format({
    protocol: req.protocol,
    host: req.get("host"),
    pathname: addPathname ? req.originalUrl : "/",
  });
}

async function passwordProtectionFunction(req, res, next) {
  const urlPage = getFullUrlFunction(req, true);

  if (isFile(req.originalUrl)) {
    return next();
  }

  if (req.originalUrl === "/static/index.json") {
    return next();
  }

  const customerToken = req.cookies[COOKIE_CUSTOMER_TOKEN_NAME];
  const requestorPublicKey = req.cookies[COOKIE_REQUESTOR_PUBLIC_KEY];
  const Authorization = requestorPublicKey
    ? {
        RequestorPublicKey: { $t: requestorPublicKey },
        UserToken: { $t: customerToken },
      }
    : { Requestor: getRequestor(req.hostname) };

  const reqObj = {
    GolApi: {
      PassiveSessionId: getPassiveSessionId({ cookies: req.cookies, res }),
      Authorization: { ...Authorization },
      Settings: { Localization: { Language: null, Country: "CZ" } },
      RequestDetail: {
        DetailRequestorRequest_1: {},
      },
    },
  };

  let isAbleToLogin = true;

  const backendId = req.cookies && req.cookies.backend_id;
  const isLocalhost = req.headers.host.includes("localhost:");

  const baseUrl =
    config.golApiUrlInternal && isLocalhost
      ? config.golApiUrlInternal
      : config.golApiUrl;

  try {
    const response = await axios.post(
      `${process.env.NEXT_PUBLIC_D4_golApiUrl || baseUrl}${
        backendId ? `?backend_id=${backendId}` : ""
      }`,
      JSON.stringify(reqObj)
    );

    let registrationEnabled = true;

    if (
      response.data.GolApi.ResponseDetail.DetailRequestorResponse_1 !==
        undefined &&
      response.data.GolApi.ResponseDetail.DetailRequestorResponse_1
        .RequestorSettings.FrontendSettings !== undefined
    ) {
      const frontendSettings = JSON.parse(
        response.data.GolApi.ResponseDetail.DetailRequestorResponse_1
          .RequestorSettings.FrontendSettings.$t
      );

      isAbleToLogin =
        frontendSettings.dealerCorporateSettings?.enableAnonymousSearch !==
        "false";
      registrationEnabled =
        frontendSettings &&
        frontendSettings.dealerCorporateSettings &&
        frontendSettings.dealerCorporateSettings?.enableRegistration === "true";
    }

    if (isUnAllowedRegisterPage({ registrationEnabled, urlPage })) {
      res.writeHead(302, {
        Location: "/login",
      });

      return res.end();
    }

    if (
      isUnloggedPage({ urlPage }) &&
      !isAbleToLogin &&
      !(await isLogged(req, res))
    ) {
      const lang = req?.query?.lang ? `&lang=${req.query.lang}` : "";
      res.writeHead(302, {
        Location: `/login?isLoginRequired=true${lang}`,
      });

      return res.end();
    }

    if (isProtectedPage(urlPage) && !(await isLogged(req, res))) {
      res.writeHead(302, {
        Location: "/login",
      });

      return res.end();
    }

    await ensureValidCustomerToken(req, res);

    return next();
  } catch (e) {
    Logger.error(
      `Server error loading password protected user (${req?.hostname}) with token (${req?.cookies?.[COOKIE_CUSTOMER_TOKEN_NAME]}): `,
      e
    );
    return next();
  }
}

function isFile(url) {
  const ALLOWED_URL_TO_PASS_PROTECTION = ["/static/", "/_next"];
  if (
    ALLOWED_URL_TO_PASS_PROTECTION.some((startingUrl) =>
      url.startsWith(startingUrl)
    )
  ) {
    return true;
  }
  return false;
}

function getPassiveSessionId({ cookies, res }) {
  if (cookies.d4passiveSessionId) {
    return cookies.d4passiveSessionId;
  }

  const generatedD4passiveSessionId = `1${generateRandomNumber(
    10000000,
    99999999
  )}`;

  res.cookie("d4passiveSessionId", generatedD4passiveSessionId, {
    httpOnly: false,
    expires: false,
    sameSite: "strict",
    secure: true,
  });

  return generatedD4passiveSessionId;
}

function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function generateRandomString() {
  const result = [];
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters.length;
  const length = Math.random() * 15 + 10;
  for (let i = 0; i < length; i++) {
    result.push(
      characters.charAt(Math.floor(Math.random() * charactersLength))
    );
  }
  return result.join("");
}

module.exports = {
  getFullUrl: getFullUrlFunction,
  passwordProtection: passwordProtectionFunction,
  generateRandomNumber,
  generateRandomString,
  Logger,
};

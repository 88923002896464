import classnames from "classnames";
import { SingletonRouter, withRouter } from "next/router";
import React from "react";
import { IntlShape, defineMessages } from "react-intl";
import { connect } from "react-redux";
import { components } from "react-select";

import { AppState } from "shared/data/reducers";
import { formattedMessageParams } from "shared/messages";

import Checkbox from "@components/UI/Checkbox";
import FormattedMessage from "@components/UI/FormattedMessage";
import SimpleSelect from "@components/UI/SimpleSelect";

import { isIframe } from "@lib/iframe";

import FilterBarDayTolerance from "./FilterBarDayTolerance";

const EXPORT_HTML_PACKAGE = Boolean(process.env.NEXT_PUBLIC_EXPORT_PACKAGE);

const { onlyDirect, allAirlines, addBreakfast } = defineMessages({
  addBreakfast: formattedMessageParams("FilterBar.hotels.addBreakfast"),
  onlyDirect: formattedMessageParams("FilterBar.onlyDirect"),
  allAirlines: formattedMessageParams("FilterBar.allAirlines"),
  prolongedWeekends: formattedMessageParams("FilterBar.prolongedWeekends"),
});

interface Props {
  intl: IntlShape;
  selectAirlines: (string) => void;
  selectedTransportCompany: string;
  search: () => void;
  toleranceDays: number;
  increaseTolerance: () => void;
  decreaseTolerance: () => void;
  onDirectFlightChange: () => void;
  variableDays: boolean;
  directFlights: boolean;
  airlines: [{ value: string; label: string }];
  hotelsEnabled: boolean;
  router: SingletonRouter;
}

function FilterBar(props: Props) {
  const { intl, hotelsEnabled } = props;

  const options = [
    {
      value: "all",
      label: intl.formatMessage(allAirlines),
    },
    ...(props.airlines || []),
  ];

  const isHotelSearchForm =
    props.router.query &&
    props.router.query.typeSearch &&
    props.router.query.typeSearch === "hotels";

  return (
    <div
      className={classnames("header-search-form-additional-desktop", {
        iframe: isIframe(props.router.route),
      })}
    >
      <div
        className="header-search-form-additional-desktop-one header-search-form-additional-desktop-one-only-direct"
        style={isHotelSearchForm ? { borderRight: "none" } : {}}
      >
        {isHotelSearchForm ? (
          <div className="hidden">
            <Checkbox
              id="header-search-form-only-direct"
              customContainerClass="direct-flights-checkbox-container"
              checked={props.directFlights}
              label={intl.formatMessage(addBreakfast)}
              onClick={props.onDirectFlightChange}
            />
          </div>
        ) : (
          <Checkbox
            id="header-search-form-only-direct"
            idCheckbox="GOL_package-onlyDirect-checkbox"
            idLabel="GOL_package-textStorage-FilterBar.onlyDirect"
            customContainerClass="direct-flights-checkbox-container"
            checked={props.directFlights}
            label={intl.formatMessage(onlyDirect)}
            onClick={props.onDirectFlightChange}
          />
        )}
      </div>
      {!isHotelSearchForm && (
        <div
          className={classnames(
            "header-search-form-additional-desktop-one header-search-form-additional-desktop-one-airlines",
            {
              "no-border": !props.variableDays,
            }
          )}
        >
          <SimpleSelect
            center
            testId="GOL_package-search-airlines"
            styles={{
              menu: (base) => ({
                ...base,
                animation: "fadeIn 0.2s ease",
                borderBottomLeftRadius: "5px",
                borderBottomRightRadius: "5px",
                boxShadow: "0 0 13px 0 rgba(158, 160, 172, 0.1)",
                maxHeight: "205px",
                borderRadius: 0,
                minWidth: "330px",
              }),
              menuList: (base) => ({
                ...base,
                maxHeight: "205px",
                paddingTop: 0,
              }),
              valueContainer: (base) => ({
                ...base,
                cursor: "pointer",
                display: "flex",
                fontSize: 14,
                width: getSelectValueContainerWidthFromText(
                  options.find(
                    (airline) =>
                      airline.value === props.selectedTransportCompany
                  )?.label ?? null
                ),
                maxWidth: props.variableDays ? 130 : 190,
              }),
            }}
            components={{
              SingleValue(svprops) {
                return (
                  <components.SingleValue
                    {...svprops}
                    className={classnames("search-airlines", {
                      "search-airlines--filled": props.airlinesTouched,
                    })}
                  />
                );
              },
            }}
            isClearable={false}
            value={options.find(
              (airline) => airline.value === props.selectedTransportCompany
            )}
            options={options}
            defaultValue={options[0]}
            onChange={props.selectAirlines}
            displayOnlyAfterMount
            autoComplete="aerolines-input"
            iframeView
          />
        </div>
      )}

      {(props.variableDays || EXPORT_HTML_PACKAGE) && (
        <FilterBarDayTolerance
          decreaseTolerance={props.decreaseTolerance}
          increaseTolerance={props.increaseTolerance}
          toleranceDays={props.toleranceDays}
        />
      )}

      <div className="header-search-form-additional-desktop-one-search-button no-border">
        <button
          type="button"
          className="button-main-search button-secondary-color button-main-search-desktop iframe"
          onClick={props.search}
          id="GOL_package-textStorage-SearchForm.searchFlights"
        >
          {isHotelSearchForm ? (
            <FormattedMessage id="SearchForm.hotels.search" />
          ) : hotelsEnabled ? (
            <FormattedMessage id="SearchForm.searchFlights" />
          ) : (
            <FormattedMessage id="SearchForm.search" />
          )}
        </button>
      </div>
    </div>
  );
}

function getSelectValueContainerWidthFromText(label) {
  if (!label) {
    return 100;
  }

  let length = 0;
  label.split("").forEach((character) => {
    if (character === character.toUpperCase()) {
      length += 7;
    } else {
      length += 6;
    }
  });

  return length + 32;
}

const mapStateToProps = (state: AppState) => ({
  airlines: state.storage.airlines,
  hotelsEnabled: state.requestorConfig.hotelsEnabled,
});

export default withRouter(connect(mapStateToProps)(FilterBar));

const POSSIBLE_LANGUAGES = [
  "al",
  "bg",
  "cs",
  "de",
  "en",
  "es",
  "es-co",
  "fr",
  "hr",
  "hu",
  "mk",
  "ms",
  "nl",
  "pl",
  "ru",
  "sk",
  "sr",
  "th",
  "uk",
  "ti",
  "ur",
  "vi",
  "zh",
];

const BCP47_LANGUAGES = {
  bg: "bg-BG",
  cs: "cs-CZ",
  de: "de-DE",
  en: "en-US",
  es: "es-ES",
  "es-co": "es-CO",
  fr: "fr-FR",
  hr: "hr-HR",
  hu: "hu-HU",
  ms: "ms-MY",
  nl: "nl-NL",
  pl: "pl-PL",
  ru: "ru-RU",
  sk: "sk-SK",
  sr: "sr-RS",
  th: "th-TH",
  uk: "uk-UA",
  ur: "ur-PK",
  vi: "vi-VN",
  zh: "zh-CN",
};

const DEFAULT_LANGUAGE = ["cs"];
const MOBILE_APP_LOCALIZATIONS = {
  en: () => require("../lang/en.json"),
  cs: () => require("../lang/cs.json"),
  hr: () => require("../lang/hr.json"),
  sk: () => require("../lang/sk.json"),
  sq: () => require("../lang/sq.json"),
};

module.exports = {
  POSSIBLE_LANGUAGES,
  DEFAULT_LANGUAGE,
  MOBILE_APP_LOCALIZATIONS,
  BCP47_LANGUAGES,
};

import { COOKIE_BACKEND_ID_NAME } from "../data/cookies";
import { getCookie } from "../data/cookiesOps";
import axios from "./axios";

const sharedConfig = require("../config/sharedConfig");

const golApiUrlEnv =
  process.env.NEXT_PUBLIC_D4_golApiUrl ||
  (typeof window !== "undefined" && window?.__ENV?.NEXT_PUBLIC_D4_golApiUrl);

export default axios.create({
  baseURL: `${
    golApiUrlEnv || sharedConfig.golApiUrl
  }${getBackendIdForAxiosReq()}`,
});

function getBackendIdForAxiosReq() {
  if (process.browser) {
    if (window.location.search.includes("backend_id=")) {
      return `?backend_id=${window.location.search.split("backend_id=")[1]}`;
    }
    return "";
  }

  const backendIdValue = getCookie(COOKIE_BACKEND_ID_NAME);

  if (backendIdValue) {
    return `?backend_id=${backendIdValue}`;
  }

  return "";
}

/* eslint-disable camelcase */
import { getCookie } from "shared/data/cookiesOps";
import { SearchQuery } from "shared/data/reducers/types";
import { isFalsy } from "shared/lib/isFalsy";

type PageType =
  | "results"
  | "home"
  | "bestOffers"
  | "bestOfferResults"
  | "purchase"
  | "customerInfo"
  | "services"
  | "other";

type EventType =
  | "page"
  | "search"
  | "purchase"
  | "view_promotion"
  | "begin_checkout"
  | "add_payment_info";

type Payload = {
  event?: EventType;
  ecommerce?: any;
  [key: string]: any;
};

declare global {
  interface Window {
    dataLayer: Payload[];
  }
}

export const pageDataLayer = (
  pageType: PageType,
  user: { username?: string; email?: string }
): void => {
  const reservationsInfo = getCookie("reservationsInfo");
  const isLoggedIn = getCookie("d4customerName");

  let userEmail = user.email;

  if (
    !isLoggedIn &&
    isFalsy(userEmail) &&
    reservationsInfo &&
    pageType === "home"
  ) {
    userEmail = JSON.parse(reservationsInfo)?.[0]?.reservationEmail || "";
  }

  const payload: Payload = {
    page_type: pageType,
    user_id: user?.username || "",
    user_email: userEmail,
  };

  pushToDataLayer("page", payload);
};

const flightTypes = (intl) => ({
  ONE_WAY: {
    name: intl.formatMessage({ id: "Constant.oneWay" }),
    id: intl.formatMessage({ id: "Constant.oneWayCode" }),
  },
  RETURN: {
    name: intl.formatMessage({ id: "Constant.return" }),
    id: intl.formatMessage({ id: "Constant.returnCode" }),
  },
  MULTICITY: {
    name: intl.formatMessage({ id: "Constant.multicity" }),
    id: intl.formatMessage({ id: "Constant.multicityCode" }),
  },
  TWO_ONE_WAY: {
    name: intl.formatMessage({ id: "Constant.twoOneWay" }),
    id: intl.formatMessage({ id: "Constant.twoOneWayCode" }),
  },
});

export const searchDataLayer = (searchQuery: SearchQuery) => {
  const payload = {
    search: {
      term: `${searchQuery?.from || ""}_${searchQuery?.to || ""}`,
      tolerance: Number(searchQuery?.toleranceDays || "0"),
      airlines: `${searchQuery?.preferred_airline || ""}`,
      type: `${searchQuery?.max_transfers === "direct" ? "direct" : "all"}`,
    },
  };

  if (payload?.search?.term !== "_") {
    pushToDataLayer("search", payload);
  }
};

const flightType = (bookingData, intl) => {
  if (bookingData?.reservationsIds?.length === 1) {
    if (
      bookingData?.reservationsDetails[0]?.FlightItinerary?.FlightStream
        ?.length === 1
    ) {
      return flightTypes(intl).ONE_WAY;
    }

    if (
      bookingData?.reservationsDetails[0]?.FlightItinerary?.FlightStream
        ?.length === 2
    ) {
      return flightTypes(intl).RETURN;
    }

    if (
      bookingData?.reservationsDetails[0]?.FlightItinerary?.FlightStream
        ?.length > 2
    ) {
      return flightTypes(intl).MULTICITY;
    }
  }

  if (bookingData?.reservationsIds?.length === 2) {
    return flightTypes(intl).TWO_ONE_WAY;
  }
};

const flightTypeFlightOffer = (flightItinerary, intl, isCombined) => {
  if (isCombined === "true") {
    return flightTypes(intl).TWO_ONE_WAY;
  }

  if (flightItinerary?.FlightStream?.length === 1) {
    return flightTypes(intl).ONE_WAY;
  }

  if (flightItinerary?.FlightStream?.length === 2) {
    return flightTypes(intl).RETURN;
  }

  if (flightItinerary?.FlightStream?.length > 2) {
    return flightTypes(intl).MULTICITY;
  }

  return flightTypes(intl).ONE_WAY;
};

export const purchaseDataLayerPayload = (bookingData, intl) => {
  const discount =
    Math.abs(
      bookingData?.reservationsDetails
        .flatMap((reservation) =>
          reservation.ServicePrices?.ServicePrice?.filter(
            (service) => service.Service === "discount"
          )
        )
        .reduce((total, service) => total + parseFloat(service.Price), 0) || 0
    ) || "";

  const flights = bookingData?.reservationsDetails.flatMap((reservation) =>
    reservation.FlightItinerary?.FlightStream.flatMap((flightStream) =>
      flightStream.FlightOption?.flatMap((flightOption) =>
        flightOption.FlightSegments?.FlightSegment?.map(
          (flightSegment, index) => ({
            item_id:
              flightSegment?.MarketingAirline + flightSegment?.FlightNumber,
            item_name: `${flightSegment?.OriginAirport}_${flightSegment?.DestinationAirport}`,
            coupon: getCookie("promoCode") ?? "",
            discount,
            currency: bookingData?.currency,
            index,
            item_list_id: flightType(bookingData, intl)?.id,
            item_list_name: flightType(bookingData, intl)?.name,
            item_brand: flightSegment?.MarketingAirline,
            price: bookingData?.fullPrices?.[index],
            quantity: 1,
          })
        )
      )
    )
  );

  const items = [];
  let servicesTotal = 0;

  bookingData?.reservationsDetails?.forEach((reservation) => {
    reservation.FlightPassengerPrices.FlightPassengerPrice.forEach(
      (passengerPrice) => {
        const code = passengerPrice.Code;
        const existingItem = items.find(
          (item) =>
            item.item_id === code &&
            item.price === parseFloat(passengerPrice.FullPricePerPassenger)
        );

        if (existingItem) {
          existingItem.quantity += 1;
        } else {
          items.push({
            item_id: code,
            item_name: `${code} ${intl.formatMessage({
              id: "Reservation.gtm.ticket",
            })}`,
            currency: bookingData.currency,
            price: parseFloat(passengerPrice.FullPricePerPassenger),
            quantity: 1,
          });
        }
      }
    );

    reservation.ServicePrices.ServicePrice.forEach((service) => {
      if (service.Service !== "discount") {
        servicesTotal += parseFloat(service.Price);
      }
    });
  });

  if (servicesTotal > 0) {
    items.push({
      item_id: "SERVICES",
      item_name: "Services",
      currency: bookingData.currency,
      price: servicesTotal,
      quantity: 1,
    });
  }

  const itemsIndexed = items.map((item, index) => ({
    ...item,
    index,
  }));

  const segments = flights.map((flight, index) => ({
    segment_id: flight.item_id,
    segment_name: flight.item_name,
    segment_list_id: flight.item_list_id,
    segment_list_name: flight.item_list_name,
    segment_brand: flight.item_brand,
    index,
  }));

  const totalValue =
    itemsIndexed
      .filter((item) => item.item_id !== "SERVICES")
      .reduce((acc, item) => acc + item.price * item.quantity, 0) -
    (discount === "" ? 0 : discount) +
    servicesTotal;

  const isLoggedIn = getCookie("d4customerName");

  const payload = {
    ...(!isLoggedIn && {
      user_id: bookingData?.reservationsDetails?.[0]?.Customer?.ContactInfo?.ContactName?.$t?.replace(
        " ",
        "_"
      ),
    }),
    ...(!isLoggedIn && { user_email: bookingData.reservationsEmail }),
    ecommerce: {
      transaction_id: bookingData?.reservationsIds[0],
      value: totalValue,
      currency: bookingData?.currency,
      discount,
      shipping: 0,
      tax: 0,
      coupon: getCookie("promoCode") ?? "",
      items: itemsIndexed.map(
        // eslint-disable-next-line camelcase
        ({ item_list_id, item_list_name, item_brand, ...rest }) => rest
      ),
      segments,
    },
  };

  return payload;
};

const constructBeginCheckoutDataLayerPayload = (flightOffer, intl) => {
  const flights = flightOffer.FlightItinerary?.FlightStream.flatMap(
    (flightStream) =>
      flightStream.flatMap((flightOption, flightOptionIndex) => {
        if (!flightOffer?.selectedFlightSegment?.includes(flightOption.Key)) {
          return [];
        }

        return flightOption.FlightSegments?.FlightSegment?.map(
          (flightSegment) => ({
            item_id: `${flightSegment?.MarketingAirline}${flightSegment?.FlightNumber}`,
            item_name: `${flightSegment?.OriginAirport}_${flightSegment?.DestinationAirport}`,
            currency: flightOffer?.currency,
            index: flightOptionIndex,
            item_list_id: flightTypeFlightOffer(
              flightOffer?.FlightItinerary,
              intl,
              flightOffer?.CombinedOffer
            )?.id,
            item_list_name: flightTypeFlightOffer(
              flightOffer?.FlightItinerary,
              intl,
              flightOffer?.CombinedOffer
            )?.name,
            item_brand: flightSegment?.MarketingAirline,
            price: parseFloat(
              flightOffer.PricingDetails?.PricingDetail?.[0]?.FlightPricing
                ?.FlightPrice?.FullPrice
            ),
            quantity: 1,
          })
        );
      })
  );

  const segments = flights.map((flight, index) => ({
    segment_id: flight.item_id,
    segment_name: flight.item_name,
    segment_list_id: flight.item_list_id,
    segment_list_name: flight.item_list_name,
    segment_brand: flight.item_brand,
    index,
  }));

  const items = [];

  flightOffer.PricingDetails?.PricingDetail?.[0]?.FlightPricing?.FlightPassengerPrices?.FlightPassengerPrice.forEach(
    (passengerPrice) => {
      const code = passengerPrice.Code;
      const existingItem = items.find(
        (item) =>
          item.item_id === code &&
          item.price === parseFloat(passengerPrice.FullPricePerPassenger)
      );

      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        items.push({
          item_id: code,
          item_name: `${code} ${intl.formatMessage({
            id: "Reservation.gtm.ticket",
          })}`,
          currency: flightOffer?.currency,
          price: parseFloat(passengerPrice.FullPricePerPassenger),
          quantity: 1,
        });
      }
    }
  );

  const itemsIndexed = items.map((item, index) => ({
    ...item,
    index,
  }));

  const payload = {
    user_id: flightOffer?.user?.username || "",
    user_email: flightOffer?.user?.email || "",
    ecommerce: {
      currency: flightOffer?.currency,
      items: itemsIndexed.map(
        // eslint-disable-next-line camelcase
        ({ item_list_id, item_list_name, item_brand, ...rest }) => rest
      ),
      segments,
    },
  };

  return payload;
};

export const viewPromotionDataLayer = () => {
  pushToDataLayer("view_promotion");
};

export const beginCheckoutDataLayer = (intl, flightOffer) => {
  const payload = constructBeginCheckoutDataLayerPayload(flightOffer, intl);
  window.dataLayer.push({ ecommerce: null });
  pushToDataLayer("begin_checkout", payload);
};

export const purchaseDataLayer = (bookingData, intl) => {
  const payload = purchaseDataLayerPayload(bookingData, intl);
  pushToDataLayer("purchase", payload);
};

export const addPaymentInfo = (bookingData, intl, currency) => {
  const reservationsDetails =
    bookingData?.BookedReservations?.BookedReservation || [];
  const { fullPrices, reservationsIds } = reservationsDetails.reduce(
    (acc, reservation) => {
      acc.fullPrices += Number(reservation.FlightPrice?.FullPrice);
      acc.reservationsIds.push(reservation.ReservationId);
      return acc;
    },
    { fullPrices: 0, reservationsIds: [] }
  );

  const payload = purchaseDataLayerPayload(
    {
      reservationsDetails,
      fullPrices,
      currency,
      reservationsIds,
    },
    intl
  );

  const isLoggedIn = getCookie("d4customerName");

  if (!isLoggedIn) {
    payload.user_email =
      bookingData?.BookedReservations?.BookedReservation?.[0]?.Customer?.ContactInfo?.Email?.$t;
    payload.user_id = bookingData?.BookedReservations?.BookedReservation?.[0]?.Customer?.ContactInfo?.ContactName?.$t?.replace(
      " ",
      "_"
    );
  }

  pushToDataLayer("add_payment_info", payload);
};

const pushToDataLayer = (event: EventType, payload?: Payload) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event,
    ...payload,
  });
};

import { useState } from "react";

import { loadWebRequestorDetails } from "shared/data/actions/storage";
import { logoutUser } from "shared/data/actions/user";
import { logoutCustomer } from "shared/data/customerTokens";
import { getGolApiUrl } from "shared/lib/golCopy";

type Props = {
  dispatch?: (call: any) => void;
  router?: any;
  children?: any;
};

const HeaderLogoStripeContainer = ({ dispatch, router, children }: Props) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showMobileChangeLanguage, setShowMobileChangeLanguage] = useState(
    false
  );
  const [showMobileChangeCurrency, setShowMobileChangeCurrency] = useState(
    false
  );
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const isLogin = true;
  const showModal = false;

  const toggleMobileMenu = () => {
    setShowMobileMenu((prevState) => !prevState);
  };

  const logout = async () => {
    await logoutCustomer();
    dispatch(logoutUser());

    window.location.href = `/${
      router.query.lang ? `?lang=${router.query.lang}` : ""
    }`;
    await dispatch(
      loadWebRequestorDetails({
        selectedLanguage: router.query.lang,
        golApiUrl: getGolApiUrl(),
        requestorPublicKey: null,
      })
    );
  };

  const toggleMobileChangeLanguage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowMobileChangeLanguage((prevState) => !prevState);
  };

  const toggleMobileChangeCurrency = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setShowMobileChangeCurrency((prevState) => !prevState);
  };

  const toggleUserMenu = () => setIsUserMenuOpen((prev) => !prev);

  return children({
    state: {
      showMobileMenu,
      showMobileChangeLanguage,
      showMobileChangeCurrency,
      isUserMenuOpen,
      isLogin,
      showModal,
    },
    toggleMobileMenu,
    logout,
    toggleMobileChangeLanguage,
    toggleMobileChangeCurrency,
    toggleUserMenu,
  });
};

export default HeaderLogoStripeContainer;
